import React from 'react';
import classNames from './downloadfooter.module.scss';
import appstoreIcon from '../../assets/images/appstore.svg';
import playstoreIcon from '../../assets/images/playstore.svg';
import footermobileIcon from '../../assets/images/footermobile.svg';

const DownloadFooter = () => {
  return (
    <div className={classNames.downloadFooter}>
      <div className={classNames.contentDiv}>
        <div>
          <div>Access IndianInvestor Anytime. Anywhere</div>
          <div className={classNames.para}>
            With Our State Of The Art Mobile App
          </div>
          <div className={classNames.downloadApp}>
            <div>
              <img src={appstoreIcon} alt="appstoreIcon" />
            </div>
            <div>
              <img src={playstoreIcon} alt="playstoreIcon" />
            </div>
            {/* <div className={classNames.borderDiv}></div> */}
          </div>
        </div>
      </div>
      <img src={footermobileIcon} alt="footermobileIcon" />
    </div>
  );
};

export default DownloadFooter;
