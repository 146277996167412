import React from 'react';
import classNames from './productscomponent.module.scss';

const investorMap = [
  {
    title: 'For Retail Investors',
    ques: 'First Time Investing Into Private Companies?',
    para: 'Start building your portfolio for as little as 1k Rupees. IndianInvestor’s product line will help you discover, analyze and transact with your all your investments.',
    side: 'left',
  },
  {
    title: 'For Institutional Investors',
    ques: 'For HNI’s, Angels, VC’s, PE Firms, and more',
    para: 'Find, manage, and close more deals with our institutional platform, VCfunnel. Get all the features of IndianInvestor along with offline DD & relationship management.',
    side: 'right',
  },
];

const ProductsInvestor = () => {
  return (
    <div className={classNames.productsInvestor}>
      {investorMap.map((eachItem) => {
        return <EachContainer {...eachItem} />;
      })}
    </div>
  );
};

export default ProductsInvestor;

const EachContainer = ({ title, ques, para, side }) => {
  return (
    <div
      className={classNames.investorContainer}
      style={{
        background:
          side == 'right'
            ? 'linear-gradient(90.43deg, #4CAF50 3.17%, #4CAF50 3.18%, #93D996 105.2%)'
            : '',
        color: side == 'right' ? 'white' : '',
      }}
    >
      <div
        className={side == 'right' ? classNames.titleWhite : classNames.title}
      >
        {title}
      </div>
      <div className={classNames.ques}>{ques}</div>
      <div className={classNames.para}>{para}</div>
      <div
        className={classNames.btn}
        style={{
          background: side == 'right' ? 'transparent' : '',
          border: side == 'right' ? '2px solid #FFFFFF' : '',
        }}
      >
        This Is Me
      </div>
    </div>
  );
};
