import portfolio from '../../../static/images/portfolio.svg';
import sharetokens from '../../../static/images/sharetokens.svg';
import prospectus from '../../../static/images/prospectus.svg';
import marketwatchers from '../../../static/images/marketwatchers.svg';
import instalegal from '../../../static/images/instalegal.svg';
import taxchains from '../../../static/images/taxchains.svg';
import capital from '../../../static/images/capital.svg';

export const investormenu = [
    {
        name: "ShareTokens",
        description: 'Buy and sell tokenized shares of private companies in minutes',
        icon: sharetokens,
        link: 'https://sharetokens.com/'
    },
    {
        name: "VirtualProspectus",
        description: 'A live reporting tool that enables you to analyze private companies',
        icon: prospectus,
        link: 'https://virtualprospectus.com/'
    },
    {
        name: "Portfolio.Ai",
        description: 'Visualize your net-worth across multiple asset classes',
        icon: portfolio,
    },
    {
        name: "MarketWatchers",
        description: 'Discover the latest news, alerts, and updates from the private markets',
        icon: marketwatchers,
    },
]

export const companiesmenu = [
    {
        name: "Capitalized",
        description: 'Equity tokenization and investor relations management system.',
        icon: capital,
        link: 'https://instalegal.com/capitalization'
    },
    {
        name: "InstaLegal",
        description: 'All in one compliance, legal, and regulatory platform for startups.',
        icon: instalegal,
        link: 'https://instalegal.com/'
    },
    {
        name: "TaxChains",
        description: 'A suite of accounting, reporting, and taxation tools for startups.',
        icon: taxchains,
        link: 'https://taxchains.com/'
    },
]