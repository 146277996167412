import React, { useContext } from 'react';
import './navbar.css';
// import nvestlogo from "../../assets/logos/nvestlogo.svg";
// import topnavlogo from '../../assets/logos/topnavlogo.svg';
// import groupdotsicon from '../../assets/logos/groupdotsicon.svg';
// import usaflag from "../../assets/logos/usaflag.svg";
// import indianflag from '../../assets/logos/indianflag.svg';
// import gmaillogo from '../../assets/logos/gmaillogo.svg';

import inrIcon from '../../../static/images/topbar/inrIcon.svg';
import dotIcon from '../../../static/images/topbar/dotIcon.svg';
import mailIcon from '../../../static/images/topbar/mailIcon.svg';
import countryIcon from '../../../static/images/topbar/countryIcon.svg';
import { GlobalContext } from '../../context/globalcontext';

const TopNavbar = () => {
  const {
    setopencard,
    setpagemask,
    settherightcard,
    setclosedisp,
    setcloserightdisp,
    navOpen,
    setNavOpen,
    // seticondisp,
  } = useContext(GlobalContext);

  const openmenu = (e) => {
    setpagemask('pagemask');
    setopencard('translateX(0%)');
    setclosedisp('');
  };
  const openrightcard = (e) => {
    settherightcard('translateX(0%)');
    setpagemask('pagemask');
    setcloserightdisp('');
  };
  return (
    <div id="topNavbar">
      <div>
        <div
          style={{ borderLeft: 'none' }}
          onClick={(e) => window.open('http://nvest.group', '_blank')}
        >
          <img src={inrIcon} alt="inrIcon" />
        </div>
        <div onClick={openmenu}>
          <img src={dotIcon} alt="dotIcon" />
        </div>
      </div>
      <div>
        <div
          style={{ borderLeft: '0.5px solid #e5e5e5' }}
          onClick={openrightcard}
        >
          <img src={mailIcon} alt="mailIcon" />
        </div>
        <div style={{ borderRight: 'none' }}>
          <img src={countryIcon} alt="countryIcon" />
        </div>
      </div>
    </div>
  );
};

export default TopNavbar;
