import React, { useContext } from 'react';
import classNames from './directorypage.module.scss';
import Directory from '../../components/Directory';
import { GlobalContext } from '../../components/context/globalcontext';

const DirectoryPage = () => {
  const { setnewNavbarMenu } = useContext(GlobalContext);
  function handleMouseLeaveProduct() {
    setnewNavbarMenu('');
  }
  return (
    <div
      className={classNames.directoryPage}
      onMouseEnter={handleMouseLeaveProduct}
    >
      <Directory />
    </div>
  );
};

export default DirectoryPage;
