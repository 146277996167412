import React, { useEffect, useState } from 'react';
import { useContext } from 'react';
import fullLogo from '../../static/images/logos/fullLogo.svg';
import mlogo from '../../assets/images/mlogo.svg';
import menu from '../../assets/logos/menu.svg';
import { GlobalContext } from '../../components/context/globalcontext';
import { Link } from 'react-router-dom';
import { AiOutlineClose } from 'react-icons/ai';
// import ProductDropdown, {
//     CompanyDropdown,
//     DirectoryDropdown,
//     MarketverseDropdown,
//     CompanyDropdownRoot,
//   } from '../components/Dropdown';
const MainNavbar = () => {
  const [thedisp, setdisp] = useState(false);
  const {
    isVisible,
    productDropdown,
    setProductDropdown,
    setNavOpen,
    navOpen,
    currentPath,
    setCurrentPath,
    isScrolled,
    navItemClicked,
    setnavItemClicked,
    newNavbarMenu,
    setnewNavbarMenu,
    divheight,
    setimnavbar,
  } = useContext(GlobalContext);

  function toggleNavbar(event) {
    const scrollNavbarItems = document.querySelectorAll('.scrollNavbarItems');
    scrollNavbarItems.forEach((item) => {
      item.classList.remove('selected');
    });

    event.target.classList.add('selected');
  }

  function focusNavSearch() {
    document.getElementById('mainNavSearch').focus();
  }

  //   useEffect(() => {
  //     let anchortag = document.querySelector('#mainNavbar-right-last').childNodes;

  //     anchortag.forEach((navItem) => {
  //       navItem.addEventListener('click', mainNavChange);
  //     });
  //   }, []);

  //   function mainNavChange(event) {
  //     let anchortag = document.querySelector('#mainNavbar-right-last').childNodes;

  //     if (event.target.classList == 'mainNavActive') {
  //       anchortag.forEach((navItem) => {
  //         navItem.firstChild.classList.remove('mainNavActive');
  //       });
  //     } else {
  //       anchortag.forEach((navItem) => {
  //         navItem.firstChild.classList.remove('mainNavActive');
  //       });
  //       event.target.classList.add('mainNavActive');
  //     }
  //   }

  function handleMouseEnterProduct() {
    setnewNavbarMenu('products');
  }
  function handleMouseEnterDirectory() {
    setnewNavbarMenu('directory');
  }
  function handleMouseEnterCompany() {
    // console.log('company entered');
    setnewNavbarMenu('CompanyDropdownRoot');
  }
  function handleMouseEnterMarketverse() {
    setnewNavbarMenu('marketverse');
  }
  function handleMouseLeave() {
    setnewNavbarMenu('');
  }

  const changeNavbarColor = () => {
    if (window.scrollY <= divheight) {
      setdisp(false);
      // setnavdata(activenav)
    } else {
      setdisp(true);
    }
  };

  window.addEventListener('scroll', changeNavbarColor);
  return (
    <div>
      <div
        id="mainNavbar"
        style={{
          position: 'fixed',
          zIndex: '999999999',
          background:
            navItemClicked && window.location.pathname != '/'
              ? ''
              : isScrolled
              ? 'white'
              : 'white',
          height:
            navItemClicked && window.location.pathname != '/'
              ? ''
              : isScrolled
              ? ''
              : '75px',

          borderRadius: thedisp && '19px',
          marginLeft: thedisp && '4vw',
          marginRight: thedisp && '5vw',
          top: thedisp && '1rem',
          maxWidth: thedisp && 'calc(100% - 9vw)',
        }}
      >
        <div
          id="mainNavMenu"
          onClick={() => {
            setNavOpen(!navOpen);
          }}
        >
          {!navOpen ? (
            <img src={menu} alt="menu" />
          ) : (
            <AiOutlineClose style={{ fontSize: '30px', color: '#5f6163' }} />
          )}

          <img 
          style={{marginLeft:"2.3rem"}}
          src={mlogo}/>
        </div>
        <div
          id="mainNavbar-left"
          style={{
            border:
              navItemClicked && window.location.pathname != '/'
                ? ''
                : isScrolled
                ? ''
                : 'none',
          }}
        >
          <div>
            <Link
              to="/"
              onClick={() => {
                setnewNavbarMenu('');
                setnavItemClicked('home');
              }}
            >
              <img src={fullLogo} alt="blocklogo" id="mainNavbarLogo" />
            </Link>
          </div>
        </div>
        <div id="mainNavbar-right">
          {/* {thedisp && ( */}
            <div
              id="mainNavbar-right-last"
              style={{
                border:
                  navItemClicked && window.location.pathname != '/'
                    ? ''
                    : isScrolled
                    ? ''
                    : 'none',
                width:
                  navItemClicked && window.location.pathname != '/'
                    ? ''
                    : isScrolled
                    ? ''
                    : '35%',
                left:
                  navItemClicked && window.location.pathname != '/'
                    ? ''
                    : isScrolled
                    ? ''
                    : '22vw',
              }}
            >
              <a
                onClick={() => {
                  window.open('https://news.indianinvestor.com/', '_blank');
                }}
                //   onMouseEnter={handleMouseEnterProduct}
                // onMouseLeave={handleMouseLeaveProduct}
                to="/#"
                className={
                  !isScrolled && window.location.pathname == '/'
                    ? 'topNavlanding'
                    : 'topNavlandingDefault'
                }
              >
                <div
                  className={
                    !productDropdown &&
                    navItemClicked &&
                    window.location.pathname === '/products'
                      ? 'mainNavActive'
                      : ''
                  }
                  style={{
                    border:
                      navItemClicked && window.location.pathname != '/'
                        ? ''
                        : isScrolled
                        ? ''
                        : 'none',
                  }}
                >
                  News
                </div>
              </a>

              <a
                // href="https://insider.indianinvestor.com"
                className={
                  !isScrolled && window.location.pathname == '/'
                    ? 'topNavlanding'
                    : 'topNavlandingDefault'
                }
                onMouseEnter={handleMouseLeave}
                // style={{ pointerEvents: 'none' }}
              >
                <div
                  onClick={() => {
                    window.open(
                      'https://insider.indianinvestor.com/',
                      '_blank'
                    );
                  }}
                  className={
                    productDropdown ||
                    (navItemClicked &&
                      window.location.pathname.split('/')[1] == 'learn')
                      ? 'mainNavActive'
                      : ''
                  }
                  style={{
                    border:
                      navItemClicked && window.location.pathname != '/'
                        ? ''
                        : isScrolled
                        ? ''
                        : 'none',
                  }}
                >
                  Insider
                </div>
              </a>

              <Link
                to="#"
                // style={{ pointerEvents: 'none' }}
                className={
                  !isScrolled && window.location.pathname == '/'
                    ? 'topNavlanding'
                    : 'topNavlandingDefault'
                }
                onMouseEnter={handleMouseEnterCompany}
              >
                <div
                  style={{
                    color: ' #5F6163',
                    fontWeight: '700',
                    border:
                      navItemClicked && window.location.pathname != '/'
                        ? ''
                        : isScrolled
                        ? ''
                        : 'none',
                  }}
                >
                  About Us
                </div>
              </Link>
              <a
                href="https://app.indianinvestor.com/"
                style={{
                  textDecoration: 'none',
                  color: 'inherit',
                  display:
                    navItemClicked && window.location.pathname != '/'
                      ? ''
                      : isScrolled
                      ? ''
                      : 'none',
                }}
              >
                <div style={{ borderRight: 'none' }}>
                  <div>Login</div>
                </div>
              </a>
            </div>
          {/* )} */}
          <div
            className="topNav"
            onClick={() => {
              window.open('https://app.indianinvestor.com/signup');
            }}
            style={{
              display:
                navItemClicked && window.location.pathname != '/'
                  ? ''
                  : isScrolled
                  ? ''
                  : 'none',
            }}
          >
            Register
          </div>
          <div
            className="topNav"
            onClick={() => {
              window.open('https://app.indianinvestor.com');
            }}
            style={{
              display:
                navItemClicked && window.location.pathname != '/'
                  ? 'none'
                  : !isScrolled
                  ? ''
                  : 'none',
              color: 'white',
              // right: '15px',
              top: '50%',
              width: '138px',
              maxWidth: '138px',
              height: '45px',
              right: '5%',
              borderRadius: '35px',
              transform: ' translate(-25%, -50%)',
              background:
                'linear-gradient(270.43deg, #4CAF50 3.17%, #4CAF50 3.18%, #93D996 105.2%)',
            }}
          >
            Get Started
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainNavbar;
