import React from 'react';
import OtpInput from 'react-otp-input';
import styles from './loginModal.module.scss';

const otpRegex = new RegExp(/^\d*$/);

function LoginModalPin({ pin, setPin }) {
  const pinValidator = (pinStr) => {
    if (otpRegex.test(pinStr)) setPin(pinStr);
  };
  return (
    <div className={styles.otpWrapper}>
      <div className={styles.header}>Enter 2FA Pin</div>
      <OtpInput
        containerStyle={styles.otpInputWrapper}
        value={pin}
        onChange={(otp) => pinValidator(otp)}
        numInputs={6}
        separator={<span> </span>}
        inputStyle={styles.otpInput}
        shouldAutoFocus
      />
    </div>
  );
}

export default LoginModalPin;
