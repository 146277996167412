import React, { useContext, useEffect, useState } from 'react';
import classNames from './landingpage.module.scss';
import LandingContainer from '../../components/LandingContainer';
import StickyFooter from '../../components/StickyFooter';
import NewCommonsection from '../../components/NewCommonSection';
import firstimage from '../../assets/images/firstNewSectionImage.jpg';
import secondimage from '../../assets/images/secondNewSectionImage.jpg';
import thirdimage from '../../assets/images/thirdNewSectionImage.jpg';
import { GlobalContext } from '../../components/context/globalcontext';
import { AnimatePresence, motion } from 'framer-motion/dist/framer-motion';
import raindrops from '../../static/images/stickyFooterIcons/raindrops.svg';
import exchange from '../../static/images/stickyFooterIcons/exchange.svg';
import im from '../../static/images/stickyFooterIcons/im.svg';
import left from '../../static/images/stickyFooterIcons/left.svg';
import right from '../../static/images/stickyFooterIcons/right.svg';
import DiffContainer from '../../components/LandingContainer/DiffContainer';
import DownloadFooter from '../../components/DownloadFooter';
import MapContainer from '../../components/LandingContainer/MapContainer';

const mapData = [
  {
    heading: 'Retail Investors',
    para: 'Buy and sell shares in private equities, real estate and corporate ',
    para2: 'debt all in one place.',
    image: firstimage,
  },
  {
    heading: 'Institutional Investors',
    para: 'Buy and sell shares in private equities, real estate and corporate ',
    para2: ' debt all in one place. ',
    image: secondimage,
  },
  {
    heading: 'Investment Bankers',
    para: 'Buy and sell shares in private equities, real estate and corporate ',
    para2: 'debt all in one place. ',
    image: thirdimage,
  },
];

const LandingPage = () => {
  const { isScrolled, setnewNavbarMenu } = useContext(GlobalContext);

  function handleMouseLeaveProduct() {
    setnewNavbarMenu('');
  }

  return (
    <div
      className={classNames.homePage}
      id="homepagenew"
      onMouseEnter={handleMouseLeaveProduct}
    >
      <div className={classNames.landingPageContainer}>
        <LandingContainer />
        <MapContainer />
        <DiffContainer />
        {mapData.map((eachData, i) => {
          return (
            <NewCommonsection
              {...eachData}
              styles={i == 1 ? 'row-reverse' : ''}
            />
          );
        })}
        <DownloadFooter />
        <FooterDiv />
      </div>
    </div>
  );
};

export default LandingPage;

const FooterDiv = () => {
  const [rainDrop, setRainDrop] = useState(true);
  const [step, setStep] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setRainDrop(false);
      setInterval(() => {
        setStep((prev) => !prev);
      }, 3000);
    }, 2000);
  }, []);

  return (
    <div className={classNames.stickyFooter}>
      <AnimatePresence exitBeforeEnter>
        {rainDrop ? (
          <motion.div className={classNames.footerWrap} key="0">
            {/* <img src={raindrops} alt="" className={classNames.raindrops} /> */}
          </motion.div>
        ) : step ? (
          <motion.div
            className={`${classNames.footerWrap} ${classNames.sb}`}
            initial={{ x: '30%', opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: '-30%', opacity: 0 }}
            transition={{ ease: [0.17, 0.67, 0.83, 0.67] }}
            key="1"
          >
            <img src={left} alt="" className={classNames.left} />
            <div className={classNames.banner}>
              <img src={exchange} alt="" className={classNames.logo} />
              <span>Own IndianInvestor Via Our ShareToken Offering</span>
            </div>
            <img src={right} alt="" className={classNames.right} />
          </motion.div>
        ) : (
          <motion.div
            className={`${classNames.footerWrap} ${classNames.sb}`}
            initial={{ x: '30%', opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: '-30%', opacity: 0 }}
            transition={{ ease: [0.17, 0.67, 0.83, 0.67], duration: 0.5 }}
            key="2"
          >
            <img src={left} alt="" className={classNames.left} />
            <div className={classNames.banner}>
              <img src={exchange} alt="" className={classNames.logo} />
              <span>Own IndianInvestor Via Our ShareToken Offering</span>
            </div>
            <img src={right} alt="" className={classNames.right} />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
