export const cryptoSectionData = [
  {
    contentContainer: {
      logo: 'sharetokens',
      title: 'Invest Into Startups Via Tokenized Equity Contracts',
      themeColor: '#F47217',
      ul: {
        li1: 'Analyze Company Data',
        li2: 'Earn Dividends',
        li3: 'Buy And Sell Private Equity',
        li4: 'Integrated Into Porftolio.ai',
      },
      btnContent: 'ShareToken Markets',
    },
    direction: 'row-reverse',
  },
  // {
  //   contentContainer: {
  //     logo: 'crypto',
  //     title: 'Buy And Sell Over 60 Cryptocurrencies ',
  //     title2: 'With IndianInvestor',
  //     themeColor: '#F7931A',
  //     ul: {
  //       li1: 'Buy & Sell Crypto',
  //       li2: 'Earn Interest On All Crypto Holdings',
  //       li3: 'Connect External Wallets',
  //       li4: 'Integrated Into Porftolio.ai',
  //     },
  //     btnContent: 'Crypto Markets',
  //   },
  //   direction: 'inherit',
  // },

  // {
  //   contentContainer: {
  //     logo: 'forex',
  //     title:
  //       'Hedge Your Portfolio With Access To Over A Dozen Different FX Currencies',
  //     themeColor: '#04AF76',
  //     ul: {
  //       li1: 'Buy & Sell Forex',
  //       li2: 'Invest Into Fixed Income Bonds',
  //       li3: 'Fund Account With INR',
  //       li4: 'Integrated Into Porftolio.ai',
  //     },
  //     btnContent: 'Forex Markets',
  //   },
  //   direction: 'row-reverse',
  // },
  
  // {
  //   contentContainer: {
  //     logo: 'moneymarkets',
  //     title:
  //       'All IndianInvestor Crypto Wallets Are Integrated With Leading Defi Protocols',
  //     themeColor: '#186AB4',
  //     ul: {
  //       li1: 'Daily Interest Payments',
  //       li2: 'Compound Your Earnings',
  //       li3: 'No Commitments/Lockups',
  //       li4: 'No Gas Fees',
  //     },
  //     btnContent: 'MoneyMarkets',
  //   },
  //   direction: 'inherit',
  // },
  
  // {
  //   contentContainer: {
  //     logo: 'bonds',
  //     title: 'Turn Your Crypto Into Income Instruments With Tokenized Bonds',
  //     themeColor: '#50C7AD',
  //     ul: {
  //       li1: 'Earn Higher Interest Rates',
  //       li2: 'Hold The Principal In Any Crypto',
  //       li3: 'Formalized Bond Hash',
  //       li4: 'Access Interest Immediately',
  //     },
  //     btnContent: 'Bond Markets',
  //   },
  //   direction: 'inherit',
  // },

  // {
  //   contentContainer: {
  //     logo: 'fundcoins',
  //     title:
  //       'Buy Into Tokenized Funds Managed By The Best Traders In The World',
  //     themeColor: '#186AB3',
  //     ul: {
  //       li1: 'Analyze Traders Before Investing',
  //       li2: 'Earn Dividends On A Quarterly Basis',
  //       li3: 'Buy As Little As One FundCoin',
  //       li4: 'Own FundCoins From Multiple Traders',
  //     },
  //     btnContent: 'Fund Markets',
  //   },
  //   direction: 'inherit',
  // },

  // {
  //   contentContainer: {
  //     logo: 'nft',
  //     title: 'Invest In NFT’s Which Pay You Daily Rewards In Crypto',
  //     themeColor: '#BE34A1',
  //     ul: {
  //       li1: 'Connect Your NFT Wallets',
  //       li2: 'Earn Rewards In Multiple Cryptos',
  //       li3: 'Find Reward Paying NFT’s',
  //       li4: 'Increase The Value Of Your NFT’s',
  //     },
  //     btnContent: 'NFT Markets',
  //   },
  //   direction: 'row-reverse',
  // },
  
  // {
  //   contentContainer: {
  //     logo: 'indexfunds',
  //     title: 'Invest In NFT’s Which Pay You Daily Rewards In Crypto',
  //     themeColor: '#EE209A',
  //     ul: {
  //       li1: 'Earn Higher Interest Rates',
  //       li2: 'Hold The Principal In Any Crypto',
  //       li3: 'Formalized Bond Hash',
  //       li4: 'Access Interest Immediately',
  //     },
  //     btnContent: 'IndexFund Markets',
  //   },
  //   direction: 'row-reverse',
  // },
];
