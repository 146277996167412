import React from 'react';
import classNames from './newcommonsection.module.scss';
import rightarrow from '../../assets/images/rightArrow.svg';

const NewCommonsection = ({ image, heading, para, para2, styles }) => {
  return (
    <div
      className={classNames.commonSectionContainer}
      style={{
        flexDirection: styles ? styles : '',
        height: styles ? 'calc(70% + 90px)' : '',
        paddingBottom: styles ? '75px' : '',
      }}
    >
      <div className={classNames.contentDiv}>
        <div>
          <div className={classNames.heading}>{heading}</div>
          <div className={classNames.para}>
            <div>{para}</div>
            <div>{para2}</div>
          </div>
        </div>
        <div className={classNames.nextDiv}>
          <div>Check</div>
          <img src={rightarrow} alt="rightarrow" />
        </div>
      </div>
      <div className={classNames.imageDiv}>
        <img src={image} alt="commonimage" />
      </div>
    </div>
  );
};

export default NewCommonsection;
