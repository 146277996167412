import React from 'react';

const Reviews = () => {
  return (
    <>
      <div
        style={{
          padding: '10vh 10vw',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: '20px',
        }}
      >
        Coming Soon...
      </div>
    </>
  );
};

export default Reviews;
