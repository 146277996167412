import React from 'react';
import Bonds from '../../components/Product/Bonds';

const BondsPage = () => {
  return (
    <div>
      <Bonds />
    </div>
  );
};

export default BondsPage;
