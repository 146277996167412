import React from 'react';
import classNames from './diff.module.scss';
import { mapDiffData } from './data';

const DiffContainer = () => {
  return (
    <div className={classNames.diffContainer}>
      {mapDiffData?.map((eachItem, i) => {
        return <SingleDiv {...eachItem} index={i} />;
      })}
    </div>
  );
};

export default DiffContainer;

const SingleDiv = ({ title, image, para, mapItems, index }) => {
  return (
    <div
      className={classNames.singleDiffDiv}
      style={{
        borderLeft: index == 1 ? '0.5px solid #e5e5e5' : '',
        borderRight: index == 1 ? '0.5px solid #e5e5e5' : '',
      }}
    >
      <div className={classNames.topDiv}>
        <img src={image} alt="diffimage" />
        <div>{title}</div>
      </div>
      <div className={classNames.bottomDiv}>
        <p className={classNames.para}>{para}</p>
        <div className={classNames.itemsContainer}>
          {mapItems?.map((eachItem) => {
            return <div className={classNames.eachItem}>{eachItem}</div>;
          })}
        </div>
      </div>
    </div>
  );
};
