import React, { useContext, useState } from 'react';
import { useParams, useNavigate } from 'react-router';
import tokenHash from '../staticimp/images/logos/tokenHash.svg';
import tokenHashIcon from '../staticimp/images/logos/tokenHashIcon.svg';
import bondsIcon from '../staticimp/images/typeIcons/bonds.svg';
import fundsIcon from '../staticimp/images/typeIcons/funds.svg';
import moneyMarketIcon from '../staticimp/images/typeIcons/moneyMarket.svg';
import vaultIcon from '../staticimp/images/typeIcons/vault.svg';
import bondsIconSmall from '../staticimp/images/typeIcons/bondsicon.svg';
import fundsIconSmall from '../staticimp/images/typeIcons/fundsIcon.svg';
import moneyMarketIconSmall from '../staticimp/images/typeIcons/moneyMarketIcon.svg';
import vaultIconSmall from '../staticimp/images/typeIcons/vaultIcon.svg';
import rightCircle from '../staticimp/images/clipIcons/rightCircle.svg';
import atmsIcon from '../staticimp/images/typeIcons/atms.svg';
import atmsIconSmall from '../staticimp/images/typeIcons/atmsIcon.svg';
import nftsIcon from '../staticimp/images/typeIcons/nfts.svg';
import nftsIconSmall from '../staticimp/images/typeIcons/nftsIcon.svg';

import LoginModal from '../components/LoginModal/LoginModal';
// import { MainContext } from '../context/MainContext';
import Scrollbars from 'react-custom-scrollbars';
import { Link } from 'react-router-dom';
import { GlobalContext } from '../components/context/globalcontext';

const typesList = {
  bond: {
    icon: bondsIcon,
    iconsmall: bondsIconSmall,
    title: 'Bond',
    login: false,
  },
  funds: {
    icon: fundsIcon,
    iconsmall: fundsIconSmall,
    title: 'Funds',
    login: false,
  },
  moneymarket: {
    icon: moneyMarketIcon,
    iconsmall: moneyMarketIconSmall,
    title: 'Money Market',
    login: false,
  },
  vault: {
    icon: vaultIcon,
    iconsmall: vaultIconSmall,
    title: 'Vault',
    login: true,
  },
  atms: {
    icon: atmsIcon,
    iconsmall: atmsIconSmall,
    title: 'ATMS',
    login: true,
  },
  ntfs: {
    icon: nftsIcon,
    iconsmall: nftsIconSmall,
    title: "NTF's",
    login: false,
  },
};

function Sidebar(props) {
  const navigate = useNavigate();
  const type = useParams()?.type || props?.type;
  const { email } = useContext(GlobalContext);
  const [loginModal, setLoginModal] = useState(false);
  const [collapse, setCollapse] = useState(true);
  const [search, setSearch] = useState('');
  const [redirect, setRedirect] = useState('');

  function onLogin() {
    if (redirect) {
      navigate(redirect);
    }
    setLoginModal(false);
  }

  return (
    <>
      <div className={`sidebar ${collapse}`}>
        <Link to="/" className="sidebarHead">
          <img
            src={collapse ? tokenHashIcon : tokenHash}
            alt=""
            className="logo"
          />
          <img
            src={rightCircle}
            className="btCollapse"
            alt=""
            onClick={() => setCollapse(!collapse)}
          />
        </Link>
        {!collapse && (
          <label className="tabs">
            <input
              type="text"
              placeholder="Search Protocols...|"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </label>
        )}
        <Scrollbars className="list">
          {Object.keys(typesList)
            .filter((key) =>
              typesList[key].title?.toLowerCase().includes(search.toLowerCase())
            )
            .map((key) => (
              <div
                key={key}
                className={`listItem ${type === key}`}
                onClick={() => {
                  if (typesList[key].login ? email : true) {
                    navigate(`/${key}`);
                  } else {
                    setRedirect(`/${key}`);
                    setLoginModal(true);
                  }
                }}
              >
                <img
                  src={
                    collapse ? typesList[key].iconsmall : typesList[key].icon
                  }
                  alt=""
                />
                <div className="label">{typesList[key]?.title}</div>
              </div>
            ))}
        </Scrollbars>
      </div>
      {loginModal && (
        <LoginModal onLogin={onLogin} onClose={() => setLoginModal(false)} />
      )}
    </>
  );
}

export default Sidebar;
