import React from 'react';
import './businesslandingPage.scss';
import useWindowDimensions from '../../utilsold/WindowSize';

export const EachContainer = ({ eachdata }) => {
  const { width } = useWindowDimensions();
  return (
    <div
      className="cont1"
      style={{
        minWidth:
          width < 500 && width > 400 && eachdata.month1 && eachdata.month2
            ? '90vw'
            : width < 500 && width > 400 && eachdata.month1 && !eachdata.month2
            ? '50vw'
            : width <= 400 && width > 300 && eachdata.month1 && eachdata.month2
            ? '95vw'
            : width <= 400 && width > 300 && eachdata.month1 && !eachdata.month2
            ? '55vw'
            : eachdata.month1 && eachdata.month2
            ? '30vw'
            : '17vw',
      }}
    >
      <div className="month-cont">
        {eachdata.month1 && eachdata.month2 ? (
          <>
            <div className="month">{eachdata.month1}</div>
            <div className="month">{eachdata.month2}</div>
          </>
        ) : (
          <>
            <div className="month" style={{ width: '100%' }}>
              {eachdata.month1}
            </div>
          </>
        )}
      </div>
      <div
        className="detail-cont"
        style={{
          background:
            eachdata.button === 'Preparing'
              ? '#eff6fe'
              : eachdata.button === 'Present'
              ? '#effef7'
              : '#ffe7e7',
          borderBottom:
            eachdata.button === 'Preparing'
              ? '2px solid #4a67f5'
              : eachdata.button === 'Present'
              ? '2px solid #35981b'
              : '2px solid #e55454',
        }}
      >
        <div className="detail" style={{ marginBottom: '0.5rem' }}>
          <p
            style={{
              color:
                eachdata.button === 'Preparing'
                  ? '#4a67f5'
                  : eachdata.button === 'Present'
                  ? '#35981b'
                  : '#e55454',
            }}
          >
            {eachdata.text1}
          </p>
          <p
            style={{
              color:
                eachdata.button === 'Preparing'
                  ? '#4a67f5'
                  : eachdata.button === 'Present'
                  ? '#35981b'
                  : '#e55454',
            }}
          >
            {eachdata.text2}
          </p>
        </div>
        <p className="color">{eachdata.duration}</p>
        <div className="btnn">{eachdata.button}</div>
      </div>
    </div>
  );
};
