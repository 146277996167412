import React, { useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { motion } from 'framer-motion/dist/framer-motion';
import ios from '../../static/images/platforms/ios.svg';
import play from '../../static/images/platforms/play.svg';
import classNames from '../Navbar/Navbar.module.scss';
import useWindowDimensions from '../../utilsold/WindowSize';

const SidebarMobile = ({ onClose, setPlatform, setInviteModal }) => {
  const { width } = useWindowDimensions();
  const [searchInput, setSearchInput] = useState('');

  const { pathname } = useLocation();
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className={classNames.sidebarMobile}
      transition={{ ease: 'easeOut', duration: 0.5 }}
    >
      <div className={classNames.overlay} onClick={onClose} />
      <motion.div
        initial={{ x: '-100%' }}
        animate={{ x: 0 }}
        exit={{ x: '-100%' }}
        transition={{ ease: 'easeOut', duration: 0.5 }}
        className={classNames.menuWrap}
      >
        <div
          className={classNames.navMenu}
          style={{ maxHeight: width < 900 ? '30%' : '' }}
        >
          {width < 900 ? (
            ''
          ) : (
            <input
              className={classNames.searchInput}
              value={searchInput}
              type="text"
              onChange={(e) => setSearchInput(e.target.value)}
              placeholder="Search IndianInvestor..."
            />
          )}
          {width < 900 ? (
            ''
          ) : (
            <Link
              to="/"
              className={`${classNames.navItem} ${
                classNames[pathname === '/']
              }`}
            >
              Investors
            </Link>
          )}
          {width < 900 ? (
            ''
          ) : (
            <Link
              to="/businesses"
              className={`${classNames.navItem} ${
                classNames[pathname === '/about']
              }`}
            >
              Businesses
            </Link>
          )}

          {width < 900 ? (
            ''
          ) : (
            <Link
              to="/"
              className={`${classNames.navItem} ${classNames[pathname === '']}`}
            >
              Affiliates
            </Link>
          )}
          {width < 900 ? (
            ''
          ) : (
            <Link
              to="/"
              className={`${classNames.navItem} ${
                classNames[pathname === '/markets']
              }`}
            >
              Markets
            </Link>
          )}
          {width < 900 ? (
            ''
          ) : (
            <Link
              to="/"
              className={`${classNames.navItem} ${
                classNames[pathname === '/markets']
              }`}
            >
              Products
            </Link>
          )}
          {width < 900 ? (
            ''
          ) : (
            <div className={classNames.navItem} onClick={() => {}}>
              Login
            </div>
          )}
          {width < 900 ? (
            ''
          ) : (
            <div className={classNames.navItem} onClick={() => {}}>
              Register
            </div>
          )}

          <div
            className={classNames.btnIos}
            onClick={() => {
              setPlatform('ios');
              setInviteModal(true);
            }}
            style={{ justifyContent: 'center', maxWidth: '300px' }}
          >
            <img src={ios} alt="AppStore" className={classNames.icon} />
            <div className={classNames.texts}>
              <div className={classNames.downOn}>Download On</div>
              <div className={classNames.sorce}>App Store</div>
            </div>
          </div>
          <div
            className={classNames.btnAndroid}
            onClick={() => {
              setPlatform('android');
              setInviteModal(true);
            }}
            style={{ justifyContent: 'center', maxWidth: '300px' }}
          >
            <img src={play} alt="PlayStore" className={classNames.icon} />
            <div className={classNames.texts}>
              <div className={classNames.downOn}>Download On</div>
              <div className={classNames.sorce}>Google Play</div>
            </div>
          </div>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default SidebarMobile;
