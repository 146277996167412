import React, { useContext, useEffect } from 'react';
import { GlobalContext } from '../../components/context/globalcontext';
import LeftComponent, {
  RightComponent,
} from '../../components/ProductsComponent';
import ProductsInvestor from '../../components/ProductsComponent/ProductsInvestor';
import classNames from './products.module.scss';

const ProductsNew = () => {
  const { navItemClicked, setnavItemClicked } = useContext(GlobalContext);

  const { setnewNavbarMenu } = useContext(GlobalContext);

  function handleMouseLeaveProduct() {
    setnewNavbarMenu('');
  }

  return (
    <div
      className={classNames.productsPage}
      onMouseEnter={handleMouseLeaveProduct}
    >
      <ProductsInvestor />
    </div>
  );
};

export default ProductsNew;
