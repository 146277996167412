import React from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import './carousel.scss';

const responsive = {
  0: { items: 1 },
  568: { items: 1 },
  1024: { items: 1 },
  2600: { items: 1 },
};

const items = [
  <div className="item" data-value="1">
    <div className="author">
      <img
        src="https://microacquire.com/assets/img/testimonial-juan.fb5e93.png"
        alt=""
      />
      <div className="name-author">
        <p>Juan Fernando Rivero </p>
        <p>Growth Manager at SimianBot</p>
      </div>
    </div>
    <div className="content-area">
      <p>I have no words to describe how happy I am after sell my small</p>
      <p>
        bootstrapped SAAS company through IndianInvestor. Also,{' '}
        <span style={{ color: '#4caf50' }}>I made</span>
      </p>
      <p>
        <span style={{ color: '#4caf50' }}>several interesting contacts</span>{' '}
        with investors and buyers. Andrew{' '}
        <span style={{ color: '#4caf50' }}>was</span>
      </p>
      <p>
        <span style={{ color: '#4caf50' }}>
          there all the time to answer my questions.
        </span>{' '}
        Now I am working on my
      </p>
      <p> next project called SimianBot.com</p>
    </div>
  </div>,
  <div className="item" data-value="2">
    <div className="author">
      <img
        src="https://microacquire.com/assets/img/testimonial-nicolas.5c76e4.png"
        alt=""
      />
      <div className="name-author">
        <p>Nicolas Bresson-Déodat </p>
        <p>Co-Founder and CEO of Steerio</p>
      </div>
    </div>
    <div className="content-area">
      <p>
        IndianInvestor is hands down the best platform for selling your startup.
      </p>
      <p>
        After listing Steerio we had over{' '}
        <span style={{ color: '#4caf50' }}>50 serious buyers</span> reach out to
        us. We
      </p>
      <p>
        could not have expected such a{' '}
        <span style={{ color: '#4caf50' }}>strong response</span> from so many
        buyers
      </p>
      <p>
        so quickly but it helped us find a{' '}
        <span style={{ color: '#4caf50' }}>new home for our startup.</span> If
        you’re
      </p>
      <p>considering selling your company I highly recommend IndianInvestor.</p>
    </div>
  </div>,
  <div className="item" data-value="3">
    <div className="author">
      <img
        src="https://microacquire.com/assets/img/testimonial-derek.b34f81.png"
        alt=""
      />
      <div className="name-author">
        <p>Derek Homann</p>
        <p>Founder and CEO of Median</p>
      </div>
    </div>
    <div className="content-area">
      <p>
        <span style={{ color: '#4caf50' }}>I had 15+</span> folks reach out to
        me on IndianInvestor. I was completely blown
      </p>
      <p>
        away by how{' '}
        <span style={{ color: '#4caf50' }}>easy it's been to reach</span> these
        prospective buyers. We
      </p>
      <p>
        weren't necessarily looking to sell but IndianInvestor{' '}
        <span style={{ color: '#4caf50' }}>has been A+.</span> From
      </p>
      <p>
        start to finish, we were{' '}
        <span style={{ color: '#4caf50' }}>acquired within 30 days</span> at
        full asking price.
      </p>
    </div>
  </div>,
  <div className="item" data-value="4">
    <div className="author">
      <img
        src="https://microacquire.com/assets/img/testimonial-joe-benjamin@2x.f46aad.png"
        alt=""
      />
      <div className="name-author">
        <p>Joe Benjamin </p>
        <p>Founder of Revpilots</p>
      </div>
    </div>
    <div className="content-area">
      <p>We used IndianInvestor to sell our company and the experience was</p>
      <p>excellent. They helped us get our listing together and made some</p>
      <p>
        tweaks to put it over the top. Once our listing was on the marketplace
      </p>
      <p>
        our calendar{' '}
        <span style={{ color: '#4caf50' }}>
          was filled with buyer meetings.
        </span>{' '}
        It's an efficient process
      </p>
      <p>than I highly recommend.</p>
    </div>
  </div>,
];

const Carousel = () => (
  <AliceCarousel
    mouseTracking
    items={items}
    responsive={responsive}
    controlsStrategy="alternate"
    autoPlay
    autoPlayStrategy="none"
    autoPlayInterval={5000}
    animationDuration={500}
    infinite
  />
);

export default Carousel;
