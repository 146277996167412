import React, { useEffect, useState } from 'react';
import classNames from './mapcontainer.module.scss';
import Countdown from 'react-countdown';

//assets
import fullLogo from '../../../static/images/logos/fullLogo.svg';
import indianMap from '../../../assets/images/indianmap.jpg';
import startUpGirls from '../../../assets/images/startupgirls.jpg';
import investorMan from '../../../assets/images/investor.jpg';

const MapContainer = () => {
  const [days, setDays] = useState('00');
  const [hours, setHours] = useState('00');
  const [minutes, setMinutes] = useState('00');
  const [seconds, setSeconds] = useState('00');

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (days < 10) {
      setDays('0' + days);
    } else {
      setDays(days);
    }

    if (hours < 10) {
      setHours('0' + hours);
    } else {
      setHours(hours);
    }

    if (minutes < 10) {
      setMinutes('0' + minutes);
    } else {
      setMinutes(minutes);
    }

    if (seconds < 10) {
      setSeconds('0' + seconds);
    } else {
      setSeconds(seconds);
    }

    if (completed) {
      // Render a completed state
      return '';
    } else {
      // Render a countdown
      return (
        <span>
          {hours}:{minutes}:{seconds}
        </span>
      );
    }
  };

  return (
    <div className={classNames.mapContainer}>
      <div className={classNames.mainContainer}>
        <div className={classNames.contentDiv}>
          <div className={classNames.logoDiv}>
            <img src={fullLogo} alt="fullLogo" />
          </div>
          <div className={classNames.title}>SUMMIT</div>
          <p className={classNames.para}>
            Buy and sell shares in private equities, real estate and corporate
            debt all in one place.
          </p>
          <div className={classNames.countdown}>
            <div className={classNames.eachContainer}>
              <div className={classNames.countValue}>
                <div className={classNames.countValue}>{days}</div>
              </div>
              <div className={classNames.countName}>Days</div>
            </div>
            <div className={classNames.eachContainer}>
              <div className={classNames.countValue}>{hours}</div>
              <div className={classNames.countName}>Hours</div>
            </div>
            <div className={classNames.eachContainer}>
              <div className={classNames.countValue}>{minutes}</div>
              <div className={classNames.countName}>Minutes</div>
            </div>
            <div className={classNames.eachContainer}>
              <div className={classNames.countValue}>{seconds}</div>
              <div className={classNames.countName}>Seconds</div>
            </div>
            <div>
              {renderer && (
                <Countdown date={Date.now() + 5000} renderer={renderer} />
              )}
            </div>
          </div>
          <div className={classNames.btnDiv}>
            <div>Learn More</div>
            <div
              onClick={() => {
                window.open('https://app.indianinvestor.com/signup');
              }}
            >
              Register
            </div>
          </div>
        </div>
        <div className={classNames.imageDiv}>
          <div>
            <img src={startUpGirls} alt="startUpGirls" />
            <div className={classNames.content}>Startups</div>
          </div>
          <div>
            <img src={investorMan} alt="investorMan" />
            <div className={classNames.content}>Investors</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MapContainer;
