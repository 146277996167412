export const data = [
  {
    month1: 'Jan 2022',
    month2: 'Feb 2022',
    text1: 'Hire an investement',
    text2: 'Bank or Broker',
    duration: '2 months',
    button: 'Preparing',
  },
  {
    month1: 'Mar 2022',
    text1: 'Build a data',
    text2: 'room',
    duration: '1 month',
    button: 'Preparing',
  },
  {
    month1: 'Apr 2022',
    month2: 'May 2022',
    text1: 'Reach out to buyers',
    duration: '2 months',
    button: 'Preparing',
  },
  {
    month1: 'Jun 2022',
    month2: 'Jul 2022',
    text1: 'The Dog and Pony',
    text2: 'Show',
    duration: '2 months',
    button: 'Present',
  },
  {
    month1: 'Aug 2022',
    text1: 'Term Sheets',
    text2: '+ Negotiation',
    duration: '1 month',
    button: 'Present',
  },
  {
    month1: 'Sep 2022',
    month2: 'Oct 2022',
    text1: 'Due Diligence',
    duration: '2 months',
    button: 'Present',
  },
  {
    month1: 'Nov 2022',
    text1: 'Haggling',
    text2: '+ Renegotiation',
    duration: '1 month',
    button: 'Present',
  },
  {
    month1: 'Dec 2022',
    month2: 'Jan 2023',
    text1: '❌ 80% fail to close',
    text2: '✅ 20% close successfully',
    duration: '2 months',
    button: 'Close and Transition',
  },
];
