import React, { useContext, useEffect, useState } from 'react';
import './businesslandingPage.scss';
import { EachContainer } from './EachContainer';
import { data } from '../../static/data/data';
import Carousel from './Carousel';
import useWindowDimensions from '../../utilsold/WindowSize';
import MobileCarousel from './MobileCarousel';
import { GlobalContext } from '../context/globalcontext';

const BusinessNewContainer = () => {
  const [selectedTab, setSelectedTab] = useState('prepare');
  const { width } = useWindowDimensions();

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('show');
        } else {
          entry.target.classList.remove('show');
        }
      });
    });

    const hiddenElements = document.querySelectorAll('.hidden');
    hiddenElements.forEach((el) => observer.observe(el));
  }, []);

  const handleScroll = (e) => {
    if (
      e.target.scrollWidth - e.target.scrollLeft <=
      e.target.children[0].clientWidth +
        e.target.children[1].clientWidth +
        e.target.children[2].clientWidth +
        e.target.children[3].clientWidth +
        e.target.children[4].clientWidth +
        -150
    ) {
      setSelectedTab('close');
    } else if (
      e.target.scrollWidth - e.target.scrollLeft <=
      e.target.children[0].clientWidth +
        e.target.children[1].clientWidth +
        e.target.children[2].clientWidth +
        e.target.children[3].clientWidth +
        e.target.children[4].clientWidth +
        e.target.children[5].clientWidth -
        150
    ) {
      setSelectedTab('present');
    } else {
      setSelectedTab('prepare');
    }
  };

  const { setnewNavbarMenu } = useContext(GlobalContext);

  function handleMouseLeaveProduct() {
    setnewNavbarMenu('');
  }

  return (
    <div className="overall-div" onMouseEnter={handleMouseLeaveProduct}>
      <section className="businesslandingPageContainer hidden">
        <div className="left-container">
          <div className="text-block">
            <p className="fastest">
              The fastest, easiest way to sell your startup
            </p>
            <h1 className="h1-title">
              Get acquired fast and for the maximum price. Founder friendly. No
              fees. Always private.
            </h1>
            <p className="list">
              List your details, connect your metrics, and get eyes on your
              startup instantly. Hire approved advisors to help you sell or skip
              straight to the conversation. Connect with 100,000+ vetted buyers
              to get your startup acquired in as little as 30 days.
            </p>
            <button className="join">Join Now</button>
          </div>
        </div>
      </section>
      <section className="timeline-section hidden">
        <p className="heading">
          <span style={{ color: '#e55454' }}>The hard way</span>{' '}
          <span style={{ color: 'black' }}>to sell your startup</span>
        </p>
        <div className="timeline-tabs">
          <div
            className="tab1"
            onClick={() => {
              setSelectedTab('prepare');
            }}
            style={{
              opacity: selectedTab === 'prepare' ? '1' : '0.5',
              fontWeight: selectedTab === 'prepare' ? '600' : '',
              borderBottom: selectedTab === 'prepare' ? '2px solid black' : '',
            }}
          >
            Prepare and search
          </div>
          <div
            className="tab1"
            onClick={() => {
              setSelectedTab('present');
            }}
            style={{
              opacity: selectedTab === 'present' ? '1' : '0.5',
              fontWeight: selectedTab === 'present' ? '600' : '',
              borderBottom: selectedTab === 'present' ? '2px solid black' : '',
            }}
          >
            Present
          </div>
          <div
            className="tab1"
            onClick={() => {
              setSelectedTab('close');
            }}
            style={{
              opacity: selectedTab === 'close' ? '1' : '0.5',
              fontWeight: selectedTab === 'close' ? '600' : '',
              borderBottom: selectedTab === 'close' ? '2px solid black' : '',
            }}
          >
            Close and transition
          </div>
        </div>
        <div className="timeline-slider" onScroll={(e) => handleScroll(e)}>
          {data.map((eachdata, i) => {
            return <EachContainer eachdata={eachdata} />;
          })}
        </div>
      </section>
      <section className="timeline-section1 hidden">
        <p className="heading">
          <span style={{ color: '#4caf50' }}>The easy way</span>{' '}
          <span style={{ color: 'black' }}>to sell your startup</span>
        </p>
        <div className="steps hidden">
          {width < 500 ? (
            <>
              <p className="inst-heading">1 - Set up your account</p>
              <div className="carousel-container"></div>
              <div className="instructions">
                <div className="sub-text">
                  <p>
                    Provide the critical information buyers need to evaluate
                    your startup:
                  </p>
                </div>
                <br />
                <ul className="points">
                  <li>
                    Describe your startup (your company name is always private)
                  </li>
                  <li>Explain why you're selling</li>
                  <li>Integrate financial metrics (Baremetrics, ChartMogul)</li>
                  <li>Synchronize payment gateways (Stripe, Paypal)</li>
                  <li>Upload pitch deck</li>
                </ul>
              </div>
            </>
          ) : (
            <>
              <div className="carousel-container"></div>
              <div className="instructions">
                <p className="inst-heading">1 - Set up your account</p>
                <div className="sub-text">
                  <p>
                    Provide the critical information buyers need to evaluate
                    your startup:
                  </p>
                </div>
                <br />
                <ul className="points">
                  <li>
                    Describe your startup (your company name is always private)
                  </li>
                  <li>Explain why you're selling</li>
                  <li>Integrate financial metrics (Baremetrics, ChartMogul)</li>
                  <li>Synchronize payment gateways (Stripe, Paypal)</li>
                  <li>Upload pitch deck</li>
                </ul>
              </div>
            </>
          )}
        </div>
        <div className="steps hidden">
          {width < 500 ? (
            <>
              <p className="inst-heading">2 - Get help to sell</p>
              <div className="carousel-container"></div>
              <div className="instructions">
                <div className="sub-text">
                  <p>
                    Browse the world’s first in-market acquisition advisor
                    directory. Secure the best outcome for your acquisition with
                    professional help, be it taxation, legal, due diligence,
                    M&A, and more. Or, go it alone and skip straight to
                    negotiations with buyers. The choice is yours.
                  </p>
                </div>
                <br />
                <ul className="points">
                  <li>Over 100 approved advisors </li>
                  <li>Browse by rating, fee, and experience</li>
                  <li>Engage instantly through the platform</li>
                </ul>
              </div>
            </>
          ) : (
            <>
              <div className="instructions">
                <p className="inst-heading">2 - Get help to sell</p>
                <div className="sub-text">
                  <p>
                    Browse the world’s first in-market acquisition advisor
                    directory.
                  </p>
                  <p>
                    Secure the best outcome for your acquisition with
                    professional
                  </p>
                  <p>
                    help, be it taxation, legal, due diligence, M&A, and more.
                    Or, go it
                  </p>
                  <p>
                    alone and skip straight to negotiations with buyers. The
                    choice is yours.
                  </p>
                </div>
                <br />
                <ul className="points">
                  <li>Over 100 approved advisors </li>
                  <li>Browse by rating, fee, and experience</li>
                  <li>Engage instantly through the platform</li>
                </ul>
              </div>
              <div className="carousel-container"></div>
            </>
          )}
        </div>
        <div className="steps hidden">
          {width < 500 ? (
            <>
              <p className="inst-heading">3 - Connect with buyers</p>
              <div className="carousel-container"></div>
              <div className="instructions">
                <div className="sub-text">
                  <p>
                    Interested buyers will ask you for more details, including
                    MRR, churn, web analytics, number of customers, LTV, CAC,
                    and more. Once you’ve exchanged data, finalized
                    negotiations, and you’re both happy, expect a letter of
                    intent within 30 days.
                  </p>
                </div>
                <br />
                <ul className="points">
                  <li>Approve or reject information requests</li>
                  <li>Connect metrics and payment gateways</li>
                </ul>
              </div>
            </>
          ) : (
            <>
              <div className="carousel-container"></div>
              <div className="instructions">
                <p className="inst-heading">3 - Connect with buyers</p>
                <div className="sub-text">
                  <p>
                    Interested buyers will ask you for more details, including
                    MRR,
                  </p>
                  <p>
                    churn, web analytics, number of customers, LTV, CAC, and
                    more.
                  </p>
                  <p>
                    Once you’ve exchanged data, finalized negotiations, and
                    you’re
                  </p>
                  <p>both happy, expect a letter of intent within 30 days.</p>
                </div>
                <br />
                <ul className="points">
                  <li>Approve or reject information requests</li>
                  <li>Connect metrics and payment gateways</li>
                </ul>
              </div>
            </>
          )}
        </div>
      </section>
      <section className="testimonial-section hidden">
        {width < 500 ? (
          <>
            <p className="heading" style={{ color: '#25224a' }}>
              <span>What do founders say about IndianInvestor?</span>
            </p>
            <div className="carousel-div">
              <MobileCarousel />
            </div>
          </>
        ) : (
          <>
            <p className="heading" style={{ color: '#25224a' }}>
              <span>What do founders say about</span>
              <br />
              <span>IndianInvestor?</span>
            </p>
            <div className="carousel-div">
              <Carousel />
            </div>
          </>
        )}
      </section>
      <section className="ad-section hidden">
        {width < 500 ? (
          <>
            <p className="heading" style={{ color: '#25224a' }}>
              Quickly share the numbers that matter
            </p>
            <div
              className="sub-text"
              style={{ textAlign: 'center', marginTop: '1rem' }}
            >
              <p>
                Connect your financial metrics and payment gateways so your data
                is ready to share with potential buyers, instantly.
              </p>
            </div>
            <div className="external-img">
              <img
                src="https://microacquire.com/assets/img/partner-chartmogul.3b698a.svg"
                alt="chartmogul"
              />
              <img
                src="https://microacquire.com/assets/img/partner-baremetrics.23443e.svg"
                alt="baremetrics"
              />
              <img
                src="https://microacquire.com/assets/img/partner-recurly.26ebf0.svg"
                alt="recurly"
              />
            </div>
            <div className="external-img1">
              <img
                src="https://microacquire.com/assets/img/partner-stripe.8caf54.svg"
                alt="stripe"
              />
              <img
                src="https://microacquire.com/assets/img/partner-paypal.837f2f.svg"
                alt="paypal"
              />
            </div>
            <div className="join-div">
              <div className="join-heading">
                <p>
                  Join over 150,000 founders and buyers already doing business
                  on IndianInvestor
                </p>
              </div>
              <button className="join-btn">Join Now</button>
            </div>
          </>
        ) : (
          <>
            <p className="heading" style={{ color: '#25224a' }}>
              Quickly share the numbers that matter
            </p>
            <div
              className="sub-text"
              style={{ textAlign: 'center', marginTop: '1rem' }}
            >
              <p>Connect your financial metrics and payment gateways so your</p>
              <p>data is ready to share with potential buyers, instantly.</p>
            </div>
            <div className="external-img">
              <img
                src="https://microacquire.com/assets/img/partner-chartmogul.3b698a.svg"
                alt="chartmogul"
              />
              <img
                src="https://microacquire.com/assets/img/partner-baremetrics.23443e.svg"
                alt="baremetrics"
              />
              <img
                src="https://microacquire.com/assets/img/partner-recurly.26ebf0.svg"
                alt="recurly"
              />
            </div>
            <div className="external-img1">
              <img
                src="https://microacquire.com/assets/img/partner-stripe.8caf54.svg"
                alt="stripe"
              />
              <img
                src="https://microacquire.com/assets/img/partner-paypal.837f2f.svg"
                alt="paypal"
              />
            </div>
            <div className="join-div">
              <div className="join-heading">
                <p>Join over 150,000 founders</p>
                <p>and buyers already doing</p>
                <p>business on IndianInvestor</p>
              </div>
              <button className="join-btn">Join Now</button>
            </div>
          </>
        )}
      </section>
    </div>
  );
};

export default BusinessNewContainer;
