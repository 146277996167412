import React, { useContext } from 'react';
import { GlobalContext } from '../context/globalcontext';
import { Link } from 'react-router-dom';
import classNames from './dropdown.module.scss';

//assets
import companiesIcon from '../../assets/images/productdropdown/companies.svg';
import enterpriseIcon from '../../assets/images/productdropdown/enterprise.svg';
import intermediariesIcon from '../../assets/images/productdropdown/intermediaries.svg';
import investorIcon from '../../assets/images/productdropdown/investor.svg';
import propertyIcon from '../../assets/images/productdropdown/property.svg';

//directory icons
import companiesDirectoryIcon from '../../assets/images/directorydropdown/companies.svg';
import intermediariesDirectoryIcon from '../../assets/images/directorydropdown/intermediaries.svg';
import investorDirectoryIcon from '../../assets/images/directorydropdown/investor.svg';
import propertyDirectoryIcon from '../../assets/images/directorydropdown/property.svg';

//marketverse icons
import indianinvestorIcon from '../../assets/images/marketversedropdown/indianinvestor.svg';
import createappIcon from '../../assets/images/marketversedropdown/createapp.svg';
import launchIcon from '../../assets/images/marketversedropdown/launch.svg';
import integrateIcon from '../../assets/images/marketversedropdown/integrate.svg';
import sharetokenIcon from '../../assets/images/marketversedropdown/sharetoken.svg';
import safetokenIcon from '../../assets/images/marketversedropdown/safetoken.svg';
import csopIcon from '../../assets/images/marketversedropdown/csop.svg';
import esopIcon from '../../assets/images/marketversedropdown/esop.svg';
import bondtokenIcon from '../../assets/images/marketversedropdown/bondtoken.svg';
import estateIcon from '../../assets/images/marketversedropdown/estate.svg';

const productsmap = [
  {
    title: 'Investors',
    para: 'Discover, Analyze, and invest into the privately held assets',
    icon: investorIcon,
  },
  {
    title: 'Companies',
    para: 'Raise capital for your business by issuing ShareTokens & Bonds',
    icon: companiesIcon,
  },
  {
    title: 'Property Owners',
    para: 'Sell, rent, fractionalize or borrow against your property portfolio',
    icon: propertyIcon,
  },
  {
    title: 'Intermediaries',
    para: 'For Investment Bankers, Realtors, Affiliates & Wealth Managers',
    icon: intermediariesIcon,
  },
  {
    title: 'Enterprise',
    para: 'Launch your own capital markets business with our protocols',
    icon: enterpriseIcon,
  },
];

const directorymap = [
  {
    title: 'Privately Held Companies',
    para: 'Find the companies shaping the future of India',
    icon: investorDirectoryIcon,
  },
  {
    title: 'Publicly Held Companies',
    para: 'Find companies which are public but unlisted',
    icon: companiesDirectoryIcon,
  },
  {
    title: 'Real Estate',
    para: 'Find developers, REITS, and real estate management firms',
    icon: propertyDirectoryIcon,
  },
  {
    title: 'Notable People',
    para: 'The investors, founders, and executives building India’s economy',
    icon: intermediariesDirectoryIcon,
  },
];

const marketversemap = {
  firstmap: [
    {
      title: 'IndianInvestor’s Marketplace',
      para: 'Access retail and institutional investors ',
      icon: indianinvestorIcon,
    },
    {
      title: 'Your Own App/Website',
      para: 'Turn your customers into stakeholders',
      icon: createappIcon,
    },
  ],
  secondmap: [
    {
      title: 'Launch A Marketplace',
      para: 'Create your own IndianInvestor in another country',
      icon: launchIcon,
    },
    {
      title: 'Integrate Into A Marketplace',
      para: 'Integrate our protocols into an existing fintech platform',
      icon: integrateIcon,
    },
  ],
  thirdmap: [
    {
      title: 'ShareTokens',
      para: 'Representing a direct equity interest in a private company',
      icon: sharetokenIcon,
    },
    {
      title: 'SafeTokens',
      para: 'Representing a CCPS agreement in a private company',
      icon: safetokenIcon,
    },
    {
      title: 'CSOPTokens',
      para: 'Representing a CSOP agreement in a private company',
      icon: csopIcon,
    },
    {
      title: 'ESOPTokenss',
      para: 'Representing a ESOP agreement in a private company',
      icon: esopIcon,
    },
    {
      title: 'BondTokens',
      para: 'Representing various corporate debt instruments',
      icon: bondtokenIcon,
    },
    {
      title: 'EstateTokens',
      para: 'Representing ownership in a real estate asset',
      icon: estateIcon,
    },
  ],
};

const companymap = [
  {
    title: 'About Us',
    para: 'Learn about our mission, history and vision.',
    // // icon: companiesIcon,
  },
  {
    title: 'Team',
    para: 'Meet the founders and operational team that built the MarketsVerse.',
    // // icon: propertyIcon,
  },
  {
    title: 'Press',
    para: 'See everywhere MarketsVerse is mentioned in the media.',
    // // icon: intermediariesIcon,
  },
  {
    title: 'Contact',
    para: 'We would love to speak to you.',
    // // icon: enterpriseIcon,
  },
];

const ProductDropdown = () => {
  const { newNavbarMenu, setnewNavbarMenu } = useContext(GlobalContext);
  return (
    <div
      className={classNames.productDropdown}
      style={{
        display: newNavbarMenu == 'products' ? '' : 'none',
        top: window?.location?.pathname == '/' ? '67px' : '',
      }}
    >
      <div className={classNames.title}>Explore Products For:</div>
      <div className={classNames.itemsContainer}>
        {productsmap.map((eachItem) => {
          return <ProductItem {...eachItem} />;
        })}
      </div>
    </div>
  );
};

export default ProductDropdown;

const ProductItem = ({ title, para, icon }) => {
  const { newNavbarMenu, setnewNavbarMenu } = useContext(GlobalContext);
  return (
    <Link
      className={classNames.productItem}
      onClick={() => {
        if (newNavbarMenu == 'products') {
          setnewNavbarMenu('');
        } else {
          setnewNavbarMenu('products');
        }
      }}
      to={
        title == 'Investors'
          ? '/products/investors'
          : title == 'Companies'
          ? '/products/companies'
          : '#'
      }
    >
      <div>
        <img src={icon} alt={title} />
      </div>
      <div>
        <div>{title}</div>
        <div>{para}</div>
      </div>
    </Link>
  );
};

export const DirectoryDropdown = () => {
  const { newNavbarMenu, setnewNavbarMenu } = useContext(GlobalContext);
  return (
    <div
      className={classNames.directoryDropdown}
      style={{
        display: newNavbarMenu == 'directory' ? '' : 'none',
        top: window?.location?.pathname == '/' ? '67px' : '',
      }}
    >
      <div className={classNames.title}>Directory</div>
      <div className={classNames.itemsContainer}>
        {directorymap.map((eachItem) => {
          return <DirectoryItem {...eachItem} />;
        })}
      </div>
    </div>
  );
};

const DirectoryItem = ({ title, para, icon }) => {
  const { newNavbarMenu, setnewNavbarMenu } = useContext(GlobalContext);
  return (
    <Link
      className={classNames.directoryItem}
      onClick={() => {
        if (newNavbarMenu == 'directory') {
          setnewNavbarMenu('');
        } else {
          setnewNavbarMenu('directory');
        }
      }}
      to={title == 'Privately Held Companies' ? '/directory' : '#'}
    >
      <div>
        <img src={icon} alt={title} />
      </div>
      <div>
        <div>{title}</div>
        <div>{para}</div>
      </div>
    </Link>
  );
};

export const MarketverseDropdown = () => {
  const { newNavbarMenu, setnewNavbarMenu } = useContext(GlobalContext);
  return (
    <div
      className={classNames.marketverseDropdown}
      style={{
        display: newNavbarMenu == 'marketverse' ? '' : 'none',
        top: window?.location?.pathname == '/' ? '67px' : '',
      }}
    >
      <div className={classNames.seperateContainer}>
        <div>
          <div className={classNames.totalContainer}>
            <div className={classNames.title}>Raise Capital On</div>
            <div className={classNames.itemsContainer}>
              {marketversemap.firstmap.map((eachItem) => {
                return <MarketverseItem {...eachItem} />;
              })}
            </div>
          </div>
          <div className={classNames.totalContainer}>
            <div className={classNames.title}>Enterprise Options</div>
            <div className={classNames.itemsContainer}>
              {marketversemap.secondmap.map((eachItem) => {
                return <MarketverseItem {...eachItem} />;
              })}
            </div>
          </div>
        </div>
        <div>
          <div className={classNames.totalContainer}>
            <div className={classNames.title}>Raise Capital Via</div>
            <div className={classNames.itemsContainer}>
              {marketversemap.thirdmap.map((eachItem) => {
                return <MarketverseItem {...eachItem} />;
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const MarketverseItem = ({ title, para, icon }) => {
  const { newNavbarMenu, setnewNavbarMenu } = useContext(GlobalContext);
  return (
    <Link
      className={classNames.marketverseItem}
      onClick={() => {
        if (newNavbarMenu == 'marketverse') {
          setnewNavbarMenu('');
        } else {
          setnewNavbarMenu('marketverse');
        }
      }}
      to="#"
    >
      <div>
        <img src={icon} alt={title} />
      </div>
      <div>
        <div>{title}</div>
        <div>{para}</div>
      </div>
    </Link>
  );
};

export const CompanyDropdown = () => {
  const { newNavbarMenu, setnewNavbarMenu } = useContext(GlobalContext);
  return (
    <div
      className={classNames.companyDropdown}
      style={{
        display: newNavbarMenu == 'company' ? '' : 'none',
        top: window?.location?.pathname == '/' ? '67px' : '',
      }}
    >
      <div className={classNames.title}>The Company</div>
      <div className={classNames.itemsContainer}>
        {companymap.map((eachItem) => {
          return <CompanyItem {...eachItem} />;
        })}
      </div>
    </div>
  );
};

export const CompanyDropdownRoot = () => {
  const { newNavbarMenu, setnewNavbarMenu } = useContext(GlobalContext);
  return (
    <div
      className={classNames.companyDropdown}
      onMouseLeave={() => setnewNavbarMenu('')}
      style={{
        display: newNavbarMenu == 'CompanyDropdownRoot' ? '' : 'none',
        background: '#F5F5F5',
        right: '18%',
        top: '75px',
        width: '14rem',
        minHeight: '15rem',
        zIndex: '99999',
      }}
    >
      {/* <div className={classNames.title}>The Company</div> */}
      <div className={classNames.itemsContainer}>
        <Link
          className={classNames.productItem}
          onClick={() => {
            if (newNavbarMenu == 'CompanyDropdownRoot') {
              setnewNavbarMenu('');
            } else {
              setnewNavbarMenu('CompanyDropdownRoot');
            }
          }}
          to="#"
        >
          <div style={{ display: 'none' }}>
            {/* <img src={icon} alt={title} /> */}
          </div>
          <div>
            <div></div>
            <div>About us</div>
          </div>
        </Link>
        <Link
          className={classNames.productItem}
          onClick={() => {
            if (newNavbarMenu == 'CompanyDropdownRoot') {
              setnewNavbarMenu('');
            } else {
              setnewNavbarMenu('CompanyDropdownRoot');
            }
          }}
          to="#"
        >
          <div style={{ display: 'none' }}>
            {/* <img src={icon} alt={title} /> */}
          </div>
          <div>
            <div></div>
            <div>Team</div>
          </div>
        </Link>
        <Link
          className={classNames.productItem}
          onClick={() => {
            if (newNavbarMenu == 'CompanyDropdownRoot') {
              setnewNavbarMenu('');
            } else {
              setnewNavbarMenu('CompanyDropdownRoot');
            }
          }}
          to="#"
        >
          <div style={{ display: 'none' }}>
            {/* <img src={icon} alt={title} /> */}
          </div>
          <div>
            <div></div>
            <div>Contact us</div>
          </div>
        </Link>
      </div>
    </div>
  );
};

const CompanyItem = ({ title, para, icon }) => {
  const { newNavbarMenu, setnewNavbarMenu } = useContext(GlobalContext);
  return (
    <Link
      className={classNames.productItem}
      onClick={() => {
        if (newNavbarMenu == 'company') {
          setnewNavbarMenu('');
        } else {
          setnewNavbarMenu('company');
        }
      }}
      to="#"
    >
      <div style={{ display: 'none' }}>
        <img src={icon} alt={title} />
      </div>
      <div>
        <div>{title}</div>
        <div>{para}</div>
      </div>
    </Link>
  );
};
