import React from 'react';
import './retire.scss';
import ReactPlayer from 'react-player';
import { useContext } from 'react';
import { PortfolioContext } from '../../context/PortfolioContext';

const RetireVideos = ({ mappingData }) => {
  const { youtubeApiPlaylistData, setYoutubeApiPlaylistData } =
    useContext(PortfolioContext);

  return (
    <div className="retireVideosContainer">
      <div className="videoTitle">Playlists</div>
      <div className="iframesWrapper">
        {youtubeApiPlaylistData ? (
          <div>
            <img
              src={youtubeApiPlaylistData?.snippet?.thumbnails?.high?.url}
              alt=""
              onClick={() => {
                window.open(
                  `https://www.youtube.com/watch?v=${mappingData[0]?.id.videoId}&list=${youtubeApiPlaylistData.id}`,
                  '_blank'
                );
              }}
            />
            <div className="playlistTitle">
              {youtubeApiPlaylistData?.snippet?.title}
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default RetireVideos;

{
  /* <iframe
          src="https://www.youtube.com/embed/MEofmmnkYCY"
          title="retiredreact"
          width="100%"
          height="35%"
          className="hardcodedVideo"
        ></iframe>
        <iframe
          src="https://www.youtube.com/embed/JPsfq-OShog"
          title="retiredreact"
          width="100%"
          height="35%"
          className="hardcodedVideo"
        ></iframe> */
}
