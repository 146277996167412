import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import classNames from './imnavbar.module.scss';
import indianmarketsLogo from '../../assets/images/indianmarkets.svg';
import { Link } from 'react-router-dom';
import { GlobalContext } from '../context/globalcontext';

const ImNavbar = () => {
  let location = useLocation();

  const { navItemClicked, setnavItemClicked, imnavbar, setimnavbar } =
    useContext(GlobalContext);

  return (
    <div className={classNames.imNavbar}>
      <div
        className={classNames.leftNav}
        onClick={() => {
          setnavItemClicked('home');
          console.log(navItemClicked, 'clkcidi');
        }}
      >
        {location.pathname == '/im' ? (
          <Link to="/">Go Back</Link>
        ) : (
          <Link to="/">
            <img src={indianmarketsLogo} alt="indianmarketsLogo" />
          </Link>
        )}
      </div>
      <div className={classNames.rightNav}>
        <Link
          onClick={() => setimnavbar('IM')}
          to="/im"
          style={{ fontWeight: imnavbar == 'IM' ? '650' : '' }}
        >
          IM
        </Link>
        <Link to="#">Tracker</Link>
        <Link
          onClick={() => setimnavbar('Directory')}
          to="/im/directory"
          style={{ fontWeight: imnavbar == 'Directory' ? '650' : '' }}
        >
          Directory
        </Link>
      </div>
    </div>
  );
};

export default ImNavbar;
