import React from 'react';
import classNames from './stickyfooter.module.scss';
import footerLogo from '../../assets/images/footerLogo.svg';
import { AiOutlineRight, AiOutlineLeft } from 'react-icons/ai';

const StickyFooter = () => {
  return (
    <div className={classNames.stickyFooter}>
      <div>
        <AiOutlineLeft className={classNames.leftArrow} />
      </div>
      <div className={classNames.contentDiv}>
        <img src={footerLogo} alt="footerLogo" />
        <div>Own IndianInvestor Via Our ShareToken Offering</div>
      </div>
      <div>
        <AiOutlineRight className={classNames.rightArrow} />
      </div>
    </div>
  );
};

export default StickyFooter;
