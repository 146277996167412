import React, { useContext, useEffect, useState } from 'react';
import './mobileLandingPage.css';
import bitcoin from '../../assets/logos/halfcircle/bitcoin.svg';
import { GlobalContext } from '../context/globalcontext';
import {
  createGroups2,
  openAppIndianInvestorSame,
} from '../../assets/Functions/miniFunctions';
import Skeleton from 'react-loading-skeleton';
import CountUp from 'react-countup';
import { cryptoSectionData } from '../../assets/data/cryptoSection';
import rainbowinmouth from '../../assets/images/nftside/rainbowinmouth.png';
import catflying from '../../assets/images/nftside/catflying.png';
import glacierastronaut from '../../assets/images/nftside/glacierastronaut.png';
import paintedmask from '../../assets/images/nftside/paintedmask.png';
import axios from 'axios';
import apple from '../../assets/logos/apple.svg';
import playstore from '../../assets/logos/playstore.svg';

const MobileLandingPage = () => {
  const [selectedNav, setSelectedNav] = useState('ShareTokens');
  const [groupedCircleQuery, setGroupedCircleQuery] = useState();
  const [appData, setAppData] = useState();

  const [cryptoCircleM, setcryptoCircleM] = useState();
  const [forexCircleM, setforexCircleM] = useState();
  const [moneyMarkets4ContainersM, setmoneyMarkets4ContainersM] = useState();
  const [bondCurrenciesM, setbondCurrenciesM] = useState();
  const [bondQueriesM, setbondQueriesM] = useState();
  const [shareTokensSideM, setshareTokensSideM] = useState();
  const [fundSideM, setfundSideM] = useState();
  const [selectedbondCurrenciesM, setSelectedBondCurrenciesM] =
    useState('index0');

  const { navOpen, setNavOpen } = useContext(GlobalContext);

  const navItems = [
    'ShareTokens',
    'Crypto',
    'Forex',
    // 'MoneyMarkets',
    // 'Bonds',
    'FundCoins',
    'NFTs',
  ];

  function selectedSection() {
    switch (selectedNav) {
      case 'Crypto':
        return <SectionContentContainer section={cryptoSectionData[1]} />;
      case 'Forex':
        return <SectionContentContainer section={cryptoSectionData[2]} />;
      // case 'MoneyMarkets':
      //   return <SectionContentContainer section={cryptoSectionData[2]} />;
      // case 'Bonds':
      //   return <SectionContentContainer section={cryptoSectionData[4]} />;
      case 'ShareTokens':
        return <SectionContentContainer section={cryptoSectionData[0]} />;
      case 'FundCoins':
        return <SectionContentContainer section={cryptoSectionData[3]} />;
      case 'NFTs':
        return <SectionContentContainer section={cryptoSectionData[4]} />;
      default:
        return <div></div>;
    }
  }

  // useEffect(() => {
  //   const mininavbar = document.querySelector('.miniNavbarM');

  //   const observer = new IntersectionObserver(function (entries, observer) {
  //     const entry = entries[0];
  //     if (entry.intersectionRatio) {
  //       mininavbar.style.position = 'sticky';
  //       mininavbar.style.top = '79px';
  //     }
  //   });
  //   observer.observe(mininavbar);
  // }, []);

  async function getCryptoCircleData() {
    await axios
      .post('https://comms.globalxchange.com/coin/vault/service/coins/get', {
        app_code: 'indianinvestor',
        type: 'crypto',
      })
      .then((data) => {
        setcryptoCircleM(data.data);
      });
  }

  async function getForexCircleData() {
    await axios
      .post('https://comms.globalxchange.com/coin/vault/service/coins/get', {
        app_code: 'indianinvestor',
        type: 'fiat',
      })
      .then((data) => {
        setforexCircleM(data.data);
      });
  }

  async function getMoneyMarketsCircleData() {
    await axios
      .get(
        'https://comms.globalxchange.com/coin/iced/get/liquid/interest?app_code=canearn'
      )
      .then((data) => {
        setmoneyMarkets4ContainersM(data.data);
      });
  }

  async function getBondCircleData() {
    await axios
      .get(
        'https://comms.globalxchange.com/coin/iced/banker/custom/bond/get?status=active&email=shorupan@indianinvestor.com'
      )
      .then((data) => {
        let ObjOfObjs = Object.entries(data.data.currenciesData).map(
          (e) => e[1]
        );

        setbondCurrenciesM(ObjOfObjs);

        // ? bond query

        if (!bondQueriesM) {
          bondQuery(ObjOfObjs[0].coin || 'BTC');
        }
      });
  }

  async function bondQuery(selectedcoin) {
    await axios
      .get(
        `https://comms.globalxchange.com/coin/iced/banker/custom/bond/list/data/get?list_id=3ykzb16dtpl5greu5x&coin=${selectedcoin}`
      )
      .then((data) => {
        setbondQueriesM(data.data.bondsListData);
      });
  }

  async function queryBondData() {
    var selectedcoinquery = 0;

    bondCurrenciesM.forEach((data, index) => {
      if (selectedbondCurrenciesM === 'index' + index) {
        selectedcoinquery = data.coin;
      }
    });

    await axios
      .get(
        `https://comms.globalxchange.com/coin/iced/banker/custom/bond/list/data/get?list_id=5zv3h0pl2jnq2q8&coin=${selectedcoinquery}`
      )
      .then((data) => {
        setbondQueriesM(data.data.bondsListData);
      });
  }

  async function shareTokensQuery() {
    await axios
      .get(
        `https://comms.globalxchange.com/coin/investment/path/get?country=India&investmentType=EQT`
      )
      .then((data) => {
        setshareTokensSideM(data.data.paths);
      });
  }

  async function fundsQuery() {
    await axios
      .get(
        `https://comms.globalxchange.com/coin/investment/path/get?investmentType=funds`
      )
      .then((data) => {
        setfundSideM(data.data.paths);
      });
  }

  useEffect(() => {
    setcryptoCircleM();
    setforexCircleM();
    setmoneyMarkets4ContainersM();
    setbondCurrenciesM();
    setbondQueriesM();
    setshareTokensSideM();
    setfundSideM();

    switch (selectedNav) {
      case 'Crypto':
        getCryptoCircleData();
        break;
      case 'Forex':
        getForexCircleData();
        break;
      case 'MoneyMarkets':
        getMoneyMarketsCircleData();
        break;
      case 'Bonds':
        getBondCircleData();
        break;
      case 'ShareTokens':
        shareTokensQuery();
        break;
      case 'FundCoins':
        fundsQuery();
        break;
      case 'NFTs':
        break;
      default:
        break;
    }
  }, [selectedNav]);

  useEffect(() => {
    axios
      .get(
        'https://comms.globalxchange.com/gxb/apps/mobile/app/links/logs/get?app_code=indianinvestor'
      )
      .then((data) => {
        setAppData(data.data);
      });
  }, []);

  return (
    <div>
      <section className="landingDiv">
        <div className="introDiv">
          <div>
            <div>The Future Is Indian </div>
            <div>Buy & Sell Private Companies Via ShareTokens</div>
          </div>
          <div>
            <div
              onClick={() => {
                window.location.assign(
                  appData.logs[0].ios_app_link
                    ? appData.logs[0].ios_app_link
                    : ''
                );
              }}
            >
              <div
                style={{
                  minWidth: '30px',
                  minHeight: '30px',
                  maxHeight: '30px',
                  maxWidth: '30px',
                }}
              >
                <img
                  src={apple}
                  alt="apple"
                  style={{
                    minWidth: '100%',
                    minHeight: '100%',
                    maxHeight: '100%',
                    maxWidth: '100%',
                  }}
                />
              </div>
              <div style={{ marginLeft: '10px' }}>
                <div style={{ fontWeight: '500' }}>Download On</div>
                <div>App Store</div>
              </div>
            </div>
            <div
              onClick={() => {
                window.location.assign(
                  appData.logs[0].android_app_link
                    ? appData.logs[0].android_app_link
                    : ''
                );
              }}
            >
              <div
                style={{
                  minWidth: '30px',
                  minHeight: '30px',
                  maxHeight: '30px',
                  maxWidth: '30px',
                }}
              >
                <img
                  src={playstore}
                  alt="playstore"
                  style={{
                    minWidth: '100%',
                    minHeight: '100%',
                    maxHeight: '100%',
                    maxWidth: '100%',
                  }}
                />
              </div>
              <div style={{ marginLeft: '10px' }}>
                <div style={{ fontWeight: '500' }}>Download On</div>
                <div>Google Play</div>
              </div>
            </div>
          </div>
        </div>
        <div className="middleSectionM">
          <div style={{ paddingLeft: '0' }}>
            <div>
              <CountUp end={7} duration={2} />
            </div>
            <div>Listed Companies</div>
          </div>
          <div style={{ paddingRight: '0' }}>
            <div>
              <CountUp end={10} duration={2} />
              L+
            </div>
            <div>Capital Raised</div>
          </div>
          <div style={{ paddingLeft: '0' }}>
            <div>
              <CountUp end={700} duration={2} />+
            </div>
            <div>IndianInvestors</div>
          </div>
          <div style={{ paddingRight: '0' }}>
            <div>
              <CountUp end={10} duration={2} />
              CR+
            </div>
            <div>Market Cap</div>
          </div>
        </div>
        <div className="greenSectionM">
          <div>
            India’s Only Cross Asset Portfolio Management Tool Enabling Market
            Access For A New Generation Of Digital Assets
          </div>
        </div>
      </section>
      {/* <nav className="miniNavbarM" style={{ zIndex: navOpen ? '0' : '2' }}>
        <ul>
          {navItems &&
            navItems.map((item, index) => {
              return (
                <li
                  key={item + index}
                  style={{
                    fontWeight: item === selectedNav ? 800 : 400,
                    fontSize: item === selectedNav ? 17 : 13,
                  }}
                  onClick={(event) => {
                    setGroupedCircleQuery();
                    setSelectedNav(event.target.innerText);
                  }}
                >
                  {item}
                </li>
              );
            })}
        </ul>
      </nav> */}
      <section className="miniSections">
        <div className="sectionContainerM">
          {selectedSection()}
          <div className="sectionContainerCardsM">
            {selectedNav === 'Crypto' && cryptoCircleM ? (
              cryptoCircleM.coins_data.map((data, index) => {
                return (
                  <SingleCardContainer
                    singledata={data}
                    key={'cryptoSingleDiv' + index}
                  />
                );
              })
            ) : selectedNav === 'Forex' && forexCircleM ? (
              forexCircleM.coins_data.map((data, index) => {
                return (
                  <SingleCardContainer
                    singledata={data}
                    key={'cryptoSingleDiv' + index}
                  />
                );
              })
            ) : selectedNav === 'MoneyMarkets' && moneyMarkets4ContainersM ? (
              moneyMarkets4ContainersM.interest_rates.map((data, index) => {
                return (
                  <SingleCardContainerMM
                    singledata={data}
                    key={'cryptoSingleDiv' + index}
                  />
                );
              })
            ) : selectedNav === 'Bonds' && bondQueriesM ? (
              bondQueriesM.map((eachgroup, index) => {
                return (
                  <SingleDivBonds
                    singledata={eachgroup}
                    key={'cryptoSingleDiv' + index}
                  />
                );
              })
            ) : selectedNav === 'ShareTokens' && shareTokensSideM ? (
              shareTokensSideM.map((eachgroup, index) => {
                return (
                  <SingleShareTokenDiv
                    data={eachgroup}
                    key={'cryptoSingleDiv' + index}
                  />
                );
              })
            ) : selectedNav === 'FundCoins' && fundSideM ? (
              fundSideM.map((eachgroup, index) => {
                return (
                  <SingleFundSide
                    data={eachgroup}
                    key={'cryptoSingleDiv' + index}
                  />
                );
              })
            ) : selectedNav === 'NFTs' ? (
              <SingleCardContainerNft />
            ) : (
              [...new Array(2)].map((_, i) => {
                return <SingleCardContainerLoading key={'loading' + i} />;
              })
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default MobileLandingPage;

const SectionContentContainer = ({ section }) => {
  let logoImage = require(`../../assets/images/syncTransactions/${section.contentContainer.logo}.svg`);
  return (
    <>
      <div className="sectionContainerLogoM">
        <img src={logoImage} alt="crypto" />
      </div>
      <div className="sectionContainerTitleM">
        {section.contentContainer.title}
      </div>
      <div
        className="sectionContainerLineM"
        style={{
          border: `1px solid ${section.contentContainer.themeColor}`,
          background: section.contentContainer.themeColor,
        }}
      ></div>
      <div className="sectionContainerListM">
        <ul>
          <li>{section.contentContainer.ul.li1}</li>
          <li>{section.contentContainer.ul.li2}</li>
          <li>{section.contentContainer.ul.li3}</li>
          <li>{section.contentContainer.ul.li4}</li>
        </ul>
      </div>
    </>
  );
};

const SingleCardContainer = ({ singledata }) => {
  return (
    <>
      <div
        className="sectionContainerSingleCardsM"
        onClick={openAppIndianInvestorSame}
      >
        <div>
          <div className="sectionContainerSingleCardsImgM">
            <img src={singledata.coinImage} alt="bitcoin" />
          </div>
          <div>
            <div className="sectionContainerSingleCardsBoldTextM">
              {singledata.coinName}
            </div>
            <div className="sectionContainerSingleCardsLightTextM">
              {singledata.coinSymbol}
            </div>
          </div>
        </div>
        <div>
          <div className="sectionContainerSingleCardsBoldTextM">
            {singledata.price.INR ? singledata.price.INR.toFixed(2) : 0}
          </div>
          <div className="sectionContainerSingleCardsLightTextM">INR</div>
        </div>
      </div>
    </>
  );
};

const SingleCardContainerLoading = () => {
  return (
    <>
      <div
        className="sectionContainerSingleCardsM"
        style={{ marginBottom: '10px' }}
      >
        <div>
          <div className="sectionContainerSingleCardsImgM">
            <Skeleton circle width={35} height={35} />
          </div>
          <div>
            <div className="sectionContainerSingleCardsBoldTextM">
              <Skeleton width={50} />
            </div>
            <div className="sectionContainerSingleCardsLightTextM">
              <Skeleton width={30} />
            </div>
          </div>
        </div>
        <div>
          <div className="sectionContainerSingleCardsBoldTextM">
            <Skeleton width={50} />
          </div>
          <div className="sectionContainerSingleCardsLightTextM">
            <Skeleton width={30} />
          </div>
        </div>
      </div>
    </>
  );
};

const SingleCardContainerMM = ({ singledata }) => {
  return (
    <>
      <div
        className="sectionContainerSingleCardsM"
        onClick={openAppIndianInvestorSame}
      >
        <div>
          <div className="sectionContainerSingleCardsImgM">
            <img src={singledata.coin_metdata.coinImage} alt="bitcoin" />
          </div>
          <div>
            <div className="sectionContainerSingleCardsBoldTextM">
              {singledata.coin_metdata.coinName}
            </div>
            <div className="sectionContainerSingleCardsLightTextM">
              {singledata.coin_metdata.coinSymbol}
            </div>
          </div>
        </div>
        <div>
          <div className="sectionContainerSingleCardsBoldTextM">
            {(singledata.yearly_interest_rate
              ? singledata.yearly_interest_rate.toFixed(4)
              : 0) + '%'}
          </div>
          <div className="sectionContainerSingleCardsLightTextM">Per Year</div>
        </div>
      </div>
    </>
  );
};

const SingleDivBonds = ({ singledata }) => {
  return (
    <>
      <div
        className="singleDivWhole-bond"
        onClick={openAppIndianInvestorSame}
        style={{
          height: '90%',
          minHeight: '165px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <div className="singleDiv-bond">
          <div>
            <div>
              <img src={singledata.coinsData[0].coin_icon} alt={bitcoin} />
            </div>
            <div className="singleDivLeftContent-bond">
              <div>{singledata.coinsData[0].coin_name}</div>
              <div>{singledata.coinsData[0].coin}</div>
            </div>
          </div>
          <div className="singleDivLeftContent-bond">
            <div>
              {singledata.coinsData[0].bondCost
                ? singledata.coinsData[0].bondCost.toFixed(2)
                : 0}
            </div>
            <div>For {Math.round(singledata.months)} months</div>
          </div>
        </div>
        <div className="interestContainer-bond">
          <div style={{ alignItems: 'flex-start' }}>
            <div>
              {singledata.term_interest_rate
                ? singledata.term_interest_rate.toFixed(2)
                : 0 + '%'}
            </div>
            <div>Term Interest</div>
          </div>
          <div style={{ alignItems: 'flex-end' }}>
            <div>
              {singledata.coinsData[0].termEarnings
                ? singledata.coinsData[0].termEarnings.toFixed(2)
                : 0}
            </div>
            <div>Term Earnings</div>
          </div>
        </div>
      </div>
    </>
  );
};

const SingleShareTokenDiv = ({ data }) => {
  return (
    <div
      className="singleShareTokenSide"
      style={{
        borderLeft: `15px solid ${
          data.token_profile_data.color_codes[0]
            ? '#' + data.token_profile_data.color_codes[0]
            : '#F2A900'
        }`,
        minHeight: '170px',
      }}
      onClick={openAppIndianInvestorSame}
    >
      <div className="singleShareTokenSideTop">
        <div>
          <img
            src={data.token_profile_data.coinImage}
            alt={data.token_profile_data.coinName}
          />
        </div>
        <div>
          <div className="singleShareTokenSideLargeText">
            {data.token_profile_data.coinName}
          </div>
          <div className="singleShareTokenSideSmallText">
            {data.token_profile_data.coinSymbol}
          </div>
        </div>
      </div>
      <div className="singleShareTokenSideBottom" style={{ marginBottom: 0 }}>
        <div>
          <div className="singleShareTokenSideLargeText">
            ₹
            {data.asset_balance
              ? (data.asset_balance / 10000000).toFixed(2)
              : ''}
            CR
          </div>
          <div className="singleShareTokenSideSmallText">Mkt Cap</div>
        </div>
        <div>
          <div className="singleShareTokenSideLargeText">
            ₹{data.token_price ? data.token_price.toFixed(2) : 0}
          </div>
          <div className="singleShareTokenSideSmallText">Per Share</div>
        </div>
      </div>
    </div>
  );
};

const SingleFundSide = ({ data }) => {
  return (
    <div
      className="fundSideSingle"
      style={{
        borderLeft: `15px solid ${
          data.token_profile_data.color_codes[0]
            ? '#' + data.token_profile_data.color_codes[0]
            : '#F2A900'
        }`,
        minHeight: '100%',
      }}
      onClick={openAppIndianInvestorSame}
    >
      <div className="fundSideSingleTop">
        <div>
          <div>
            <img
              src={data.token_profile_data.coinImage}
              alt={data.token_profile_data.coinName}
            />
          </div>
          <div>
            <div
              className="singleShareTokenSideLargeText"
              style={{ fontSize: '10px', fontWeight: 800 }}
            >
              {data.token_profile_data.coinName}
            </div>
            <div
              className="singleShareTokenSideSmallText"
              style={{ fontSize: '10px' }}
            >
              {data.token_profile_data.coinSymbol}
            </div>
          </div>
        </div>
        <div>
          <div className="fundSideSinglePill" style={{ fontSize: '10px' }}>
            <img src={data.asset_metaData.coinImage} alt="flag" />
            <div>{data.asset}</div>
          </div>
        </div>
      </div>
      <div className="fundSideSingleMiddle">
        <div>
          <div className="fundSideSingleBigText" style={{ fontSize: '10px' }}>
            Token Supply
          </div>
          <div className="fundSideSingleSmallText" style={{ fontSize: '10px' }}>
            {data.circulating_supply ? data.circulating_supply.toFixed(2) : ''}
          </div>
        </div>
        <div style={{ alignItems: 'flex-end' }}>
          <div className="fundSideSingleBigText" style={{ fontSize: '10px' }}>
            Market Cap
          </div>
          <div className="fundSideSingleSmallText" style={{ fontSize: '10px' }}>
            {data.asset_balance ? data.asset_balance.toFixed(4) : 0}
          </div>
        </div>
      </div>
      <div className="fundSideSingleBottom">
        <div style={{ alignItems: 'flex-start' }}>
          <div className="fundSideSingleBigText" style={{ fontSize: '10px' }}>
            Fund Holders
          </div>
          <div className="fundSideSingleSmallText" style={{ fontSize: '10px' }}>
            {data.tokenHoldersCount ? data.tokenHoldersCount.toFixed(2) : ''}
          </div>
        </div>
        <div style={{ alignItems: 'flex-end' }}>
          <div className="fundSideSingleBigText" style={{ fontSize: '10px' }}>
            Price Per IOTCE
          </div>
          <div className="fundSideSingleSmallText" style={{ fontSize: '10px' }}>
            {data.token_price ? data.token_price.toFixed(5) : ''}
          </div>
        </div>
      </div>
    </div>
  );
};

const SingleCardContainerNft = () => {
  return (
    <>
      <div className="nftImgDiv">
        <img
          src={rainbowinmouth}
          alt="rainbowinmouth"
          onClick={openAppIndianInvestorSame}
        />
        <img
          src={catflying}
          alt="catflying"
          onClick={openAppIndianInvestorSame}
        />
        <img
          src={glacierastronaut}
          alt="glacierastronaut"
          onClick={openAppIndianInvestorSame}
        />
        <img
          src={paintedmask}
          alt="paintedmask"
          onClick={openAppIndianInvestorSame}
        />
      </div>
    </>
  );
};
