import React, { useContext } from 'react';
import BusinessNewContainer from '../../components/businesspagelanding/businessNewContainer';
import { GlobalContext } from '../../components/context/globalcontext';

const BusinessPage = () => {
  return (
    <>
      <BusinessNewContainer />
    </>
  );
};

export default BusinessPage;
