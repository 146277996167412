import React from 'react';
import classNames from './impage.module.scss';
import indianmarketsLogo from '../../assets/images/indianmarkets.svg';
import { BiSearch } from 'react-icons/bi';

import testImage from './testimage.svg';

const Impage = () => {
  return (
    <div className={classNames.impage}>
      <img src={indianmarketsLogo} alt="indianmarketsLogo" />
      <div className={classNames.searchDiv}>
        <input type="text" placeholder="Search here..." />
        <BiSearch />
      </div>
      <div className={classNames.contentDiv}>
        <SingleDiv />
        <SingleDiv />
        <SingleDiv />
        <SingleDiv />
        <SingleDiv />
        <SingleDiv />
        <SingleDiv />
      </div>
    </div>
  );
};

export default Impage;

const SingleDiv = () => {
  return (
    <div className={classNames.eachCard}>
      <img src={testImage} alt="testImage" />
      <div className={classNames.title}>AiProWallet</div>
      <div className={classNames.location}>Hyderabad, TL</div>
      <div className={classNames.asset}>#Crypto</div>
    </div>
  );
};
