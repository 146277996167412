import React from 'react';
import './root.scss';
import dropdownImage from '../../assets/images/dropdownImage.svg';
import desktop from '../../assets/images/desktop.png'
import discort from '../../assets/images/discort.svg';
import insta from '../../assets/images/insta.svg';
import twiter from '../../assets/images/twiter.svg';
import linkdin from '../../assets/images/linkdin.svg';

import logofooter from '../../assets/images/logofooter.svg';
export default function index() {
  return (
    <>
      <div className="rootFile">
        <h1>Empower Investments, Unleash Capital</h1>
        <p>
          India’s Largest Finance Marketplace empowering discovery, investments,
          acquisitions, communities, and social finance.
        </p>
        <label>Get Started</label>
      </div>

      <div className="discoverSetion">
        <h3>One Platform, Many Possibilities</h3>
        <div className="tabLayout">
          <div
            style={{
              background: '#FFFFFF',
            }}
          >
            Discover
          </div>
          <span></span>
          <div>Invest</div> <span></span>
          <div>Manage Portfolio</div> <span></span>
          <div>Empower Capital</div>
        </div>
        <div className='sectionHanding'>
          <div className='left'>
            <h1>Discover opportunities</h1>
            <p>
              Join the India’s Largest Social Finance and Investment platform to
              discover investment opportunities, communities, and network.
            </p>
          </div>
          <img src={desktop} />
        </div>
      </div>

      <div className="marketSection">
        <h1>Marketplace</h1>
        <h1>Curated For the Best</h1>
        <div className="tab">
          <div
          style={{
            background: "#73C676",
            color:"white",
            border:"none"
          }}
          >Startup</div>
          <div>Capital Investor</div>
          <div>Accelerators</div>
          <div>Venture or Family Office</div>
        </div>
        <div className="Info">
          <div className="leftSide">
            <h2>Embrace Your Startup With Us</h2>
            <p>
              The fastest way to scale and drive your startup with all the tools
              you need in One Platform - Indian Investor
            </p>
          </div>
          <div className="rightSide">
            <div>Raise Capital</div>
            <div>Access to Investors</div>
            <div>Build Communities</div>
            <div>Get Acquired</div>
            <span>
              <img src={dropdownImage} />
            </span>
          </div>
        </div>
      </div>

      <div className="accessBanner">
        <label>Get Early Access</label>
      </div>

      <div className="boderbanner"></div>
      <div className="footerSection">
        <div>
          <h1
            style={{
              fontWeight: '700',
            }}
          >
            Platform
          </h1>
          <h1>Founder</h1>
          <h1>Investor</h1>
          <h1>Accelarator</h1>
          <h1>Venture Firm</h1>
          <h1>Family Office</h1>
        </div>
        <div>
          <h1
            style={{
              fontWeight: '700',
            }}
          >
            Products
          </h1>
          <h1>ShareTokens</h1>
          <h1>ESOPTokens</h1>
          <h1>CSOPTokens</h1>
          <h1>CCPSTokens</h1>
          <h1>NCD/CCD</h1>
        </div>
        <div>
          <h1
            style={{
              fontWeight: '700',
            }}
          >
            Company
          </h1>
          <h1>About us</h1>
          <h1>Team</h1>
          <h1>Careers</h1>
        </div>
        <div>
          <h1
            style={{
              fontWeight: '700',
            }}
          >
            Resources
          </h1>
          <h1>Blogs</h1>
          <h1>Community</h1>
          <h1>Insider</h1>
          <h1>Case studies</h1>
          <h1>Newsletters</h1>
        </div>
        <div>
          <h1
            style={{
              fontWeight: '700',
            }}
          >
            Help & Support
          </h1>
          <h1>support@indiainvestor.com</h1>
          <h1>91 8073190014</h1>
          <h1>FAQs</h1>
          <div className="socalMedia">
            <img src={discort} />
            <img src={insta} />
            <img src={linkdin} />
            <img src={twiter} />
          </div>
        </div>
      </div>
      <div className="boderbanner"></div>
      <div className="bottomContentSection">
        <div className="leftsideHolder">
          <h1>
            Copyrights 2023 Indian Investor - Terms and Conditions - Privacy
            Policy
          </h1>
          <img src={logofooter} />
        </div>
        <p>
          All product and company names are trademarks or registered trademarks
          of their respective holders. Use of them does not imply any
          affiliation with or endorsement by them.
        </p>
        <h1>Disclaimer</h1>
        <p >
          All trademarks and logos or registered trademarks and logos found on
          this site or mentioned herein belong to their respective owners and
          are solely being used for informational purposes. Information provided
          herein has been gathered from public sources. Imventure Services Pvt
          Ltd disclaims any and all responsibility in connection with veracity
          of this data. Information presented on this website is for educational
          purposes only and should not be treated as legal, financial , or any
          other form of advice. Imventure Services Pvt Ltd is not liable for
          financial or any other form of loss incurred by the user or any
          affiliated party on the basis of information provided herein.
          Imventure Services Pvt Ltd is neither a stock exchange nor does it
          intend to get recognized as a stock exchange under the Securities
          Contracts Regulation Act, 1956. Imventure Services Pvt Ltd has not
          been authorized by the capital markets regulator to solicit
          investments. Tyke also provides that it does not facilitate any online
          or offline buying, selling, or trading of securities. This Site will
          be updated on a regular basis.
        </p>
      </div>
    </>
  );
}



