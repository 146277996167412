import React, { useEffect } from 'react';
import { useContext } from 'react';
import fullLogo from '../../../static/images/logos/fullLogo.svg';
import menu from '../../../assets/logos/menu.svg';
import { GlobalContext } from '../../context/globalcontext';
import { Link } from 'react-router-dom';
import { AiOutlineClose } from 'react-icons/ai';
import { useLocation } from 'react-router-dom';

const MainNavbar = () => {
  const {
    isVisible,
    productDropdown,
    setProductDropdown,
    setNavOpen,
    navOpen,
    currentPath,
    setCurrentPath,
    setnavItemClicked,
    newNavbarMenu,
    setnewNavbarMenu,
    navItemClicked,
  } = useContext(GlobalContext);

  const location = useLocation();

  function toggleNavbar(event) {
    const scrollNavbarItems = document.querySelectorAll('.scrollNavbarItems');
    scrollNavbarItems.forEach((item) => {
      item.classList.remove('selected');
    });

    event.target.classList.add('selected');
  }

  function focusNavSearch() {
    document.getElementById('mainNavSearch').focus();
  }

  useEffect(() => {
    let anchortag = document.querySelector('#mainNavbar-right-last').childNodes;

    anchortag.forEach((navItem) => {
      navItem.addEventListener('click', mainNavChange);
    });
  }, []);

  function mainNavChange(event) {
    let anchortag = document.querySelector('#mainNavbar-right-last').childNodes;

    anchortag.forEach((navItem) => {
      navItem.firstChild.classList.remove('mainNavActive');
    });

    event.target.classList.add('mainNavActive');
  }

  function handleMouseEnterProduct() {
    setnewNavbarMenu('products');
  }
  function handleMouseLeaveProduct() {
    setnewNavbarMenu('');
  }
  function handleMouseEnterDirectory() {
    setnewNavbarMenu('directory');
  }
  function handleMouseLeaveDirectory() {
    setnewNavbarMenu('');
  }
  function handleMouseEnterMarketverse() {
    setnewNavbarMenu('marketverse');
  }
  function handleMouseEnterCompany() {
    // console.log('company entered');
    setnewNavbarMenu('company');
  }
  return (
    <div>
      <div id="mainNavbar">
        <div
          id="mainNavMenu"
          onClick={() => {
            setNavOpen(!navOpen);
          }}
        >
          {!navOpen ? (
            <img src={menu} alt="menu" />
          ) : (
            <AiOutlineClose style={{ fontSize: '30px', color: '#5f6163' }} />
          )}
        </div>
        <div id="mainNavbar-left">
          <div>
            <Link
              onClick={() => {
                setnewNavbarMenu('');
                if (navItemClicked == 'home') {
                  setnavItemClicked('gf');
                }
                setnavItemClicked('home');
              }}
              to="/"
            >
              <img src={fullLogo} alt="blocklogo" id="mainNavbarLogo" />
            </Link>
          </div>
        </div>
        <div id="mainNavbar-right">
          <div id="mainNavbar-right-last">
            <Link
              to="#"
              onClick={() => {
                if (newNavbarMenu == 'products') {
                  setnewNavbarMenu('');
                } else {
                  setnewNavbarMenu('products');
                }
              }}
              onMouseEnter={handleMouseEnterProduct}
              // onMouseLeave={handleMouseLeaveProduct}
            >
              <div
                className={
                  !productDropdown && location.pathname == '/products'
                    ? 'mainNavActive'
                    : ''
                }
              >
                Products
              </div>
            </Link>
            <Link
              to="/im"
              onClick={() => {
                if (newNavbarMenu == 'directory') {
                  setnewNavbarMenu('');
                } else {
                  setnewNavbarMenu('directory');
                }
              }}
            >
              <div
                className={
                  !productDropdown && location.pathname == '/directory'
                    ? 'mainNavActive'
                    : ''
                }
                onMouseEnter={handleMouseEnterDirectory}
                // onMouseLeave={handleMouseLeaveDirectory}
              >
                Directory
              </div>
            </Link>
            <Link
              onClick={() => {
                if (newNavbarMenu == 'marketverse') {
                  setnewNavbarMenu('');
                } else {
                  setnewNavbarMenu('marketverse');
                }
              }}
              onMouseEnter={handleMouseEnterMarketverse}
              // onMouseLeave={handleMouseLeaveMarketverse}
              to="#"
            >
              <div
                className={
                  !productDropdown && location.pathname == '/marketsverse'
                    ? 'mainNavActive'
                    : ''
                }
              >
                Protocols
              </div>
            </Link>
            <Link to="#" style={{ pointerEvents: 'none' }}>
              <div
                onClick={() => {
                  setProductDropdown(!productDropdown);
                }}
                className={
                  productDropdown || location.pathname == '/learn'
                    ? 'mainNavActive'
                    : ''
                }
              >
                Insider
              </div>
            </Link>
            <Link to=".#" onMouseEnter={handleMouseEnterCompany}>
              <div>Company</div>
            </Link>
            <a
              href="https://app.indianinvestor.com/"
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              <div style={{ borderRight: 'none' }}>
                <div>Login</div>
              </div>
            </a>
          </div>
          <div
            className="topNav"
            onClick={() => {
              window.open('https://app.indianinvestor.com/signup');
            }}
          >
            Register
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainNavbar;
