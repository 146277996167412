import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import dollarIcon from '../../../static/images/templateImages/dollar.webp';
import arrowUp from '../../../static/images/templateImages/arrowUp.webp';
import img1 from '../../../static/images/templateImages/img1.jpg';
import img2 from '../../../static/images/templateImages/img2.jpg';
import img3 from '../../../static/images/templateImages/img3.jpg';
import img4 from '../../../static/images/templateImages/img4.jpg';
import img5 from '../../../static/images/templateImages/img5.jpg';
import img6 from '../../../static/images/templateImages/img6.jpg';
import img7 from '../../../static/images/templateImages/img7.jpg';
import img8 from '../../../static/images/templateImages/img8.gif';
import img9 from '../../../static/images/templateImages/img9.jpg';
import img10 from '../../../static/images/templateImages/img10.jpg';
import img11 from '../../../static/images/templateImages/img11.jpg';
import img12 from '../../../static/images/templateImages/img12.jpg';
import img13 from '../../../static/images/templateImages/img13.jpg';
import img14 from '../../../static/images/templateImages/img14.jpg';
import img15 from '../../../static/images/templateImages/img15.jpg';
import img16 from '../../../static/images/templateImages/img16.jpg';
import img17 from '../../../static/images/templateImages/img17.jpg';
import img18 from '../../../static/images/templateImages/img18.jpg';
import img19 from '../../../static/images/templateImages/img19.jpg';
import img20 from '../../../static/images/templateImages/img20.png';
import img21 from '../../../static/images/templateImages/img21.gif';
import img22 from '../../../static/images/templateImages/img22.jpg';
import img23 from '../../../static/images/templateImages/img23.jpg';
import img24 from '../../../static/images/templateImages/img24.jpg';
import img25 from '../../../static/images/templateImages/img25.jpg';
import img26 from '../../../static/images/templateImages/img26.jpg';
import img27 from '../../../static/images/templateImages/img27.jpg';
import staticmap from '../../../static/images/templateImages/staticmap.png';
import avatar1 from '../../../static/images/templateImages/avatar1.webp';
import avatar2 from '../../../static/images/templateImages/avatar2.webp';
import avatar3 from '../../../static/images/templateImages/avatar3.webp';
import avatar4 from '../../../static/images/templateImages/avatar4.webp';
import avatar5 from '../../../static/images/templateImages/avatar5.webp';
import avatar6 from '../../../static/images/templateImages/avatar6.webp';
import avatar7 from '../../../static/images/templateImages/avatar7.webp';

import { FiStar, FiShare } from 'react-icons/fi';
import { IoIosFlash } from 'react-icons/io';
import { GrDocumentText } from 'react-icons/gr';
import Popup from 'reactjs-popup';

import styles from './pitch.module.scss';

const Pitch = ({ handleScroll, selectedSectionId, setSelectedSectionId }) => {
  const [selectedTab, setSelectedTab] = useState('Pitch');
  const [stickyTabs, setStickyTabs] = useState(false);
  const [showDesc, setShowDesc] = useState('');
  const [selectedThumb, setSelectedThumb] = useState(1);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedNav, setSelectedNav] = useState('pitch');
  const [selectedFaq, setSelectedFaq] = useState();
  const [selectedRisk, setSelectedRisk] = useState();

  const [scrollTo, setScrollTo] = useState(false);

  useEffect(() => {
    setSelectedFaq(faqArr[0]);
    setSelectedRisk(risksArr[0]);
  }, []);

  const teamMembers = [
    {
      name: 'Jeffrey Kerr',
      designation: 'Founder and CEO',
      desc: 'Entrepreneur & founder of ACFN, the 7th fastest growing private company in Silicon Valley in 2000 and 4th fastest in 2001. Launched ACFN’s franchise in 2003 nationwide to significant growth and accolades over 19 years.',
      link: '',
    },
    {
      name: 'Avi Blankroth',
      designation: 'Co-Founder and Executive VP',
      desc: 'Successful serial entrepreneur. Founded natural food supermarket, successfully exited from a national credit card processing company in the 1990s, and grew another 935 distributor network in Australia before co-founding ACFN Franchised Inc. in 2003.',
      link: '',
    },
    {
      name: 'Dana Kerr',
      designation: 'Secretary of Corporation and Network Compliance Manager',
      desc: 'Serial business manager with over 37 years of experience holding supportive and managerial roles for a wide range of enterprises.',
      link: '',
    },
    {
      name: 'Gershon Yakir',
      designation: 'VP of Operations',
      desc: 'Engineer and entrepreneur with extensive global operations and marketing experience, including for the Israeli Navy, a telecom company in southeast Asia, and IP and data communications company in New Jersey. Joined ACFN in 2010.',
      link: '',
    },
    {
      name: 'Jim Diltz',
      designation: 'VP of Sales and Marketing',
      desc: 'Progressed from top salesperson to district manager at Alsco, Inc., accomplishing #1 district in growth and profitability in Denver. He achieved the same in San Francisco and grew to oversee 5 districts in the Bay Area before joining ACFN in 2006.',
      link: '',
    },
    {
      name: 'Nida Crisostomo',
      designation: 'Controller',
      desc: 'CPA and MBA, with 25 years of accounting, teaching, and reporting experience in higher education, public, private, and non-profit settings.',
      link: '',
    },
    {
      name: 'Roxana LaChance',
      designation: 'VP Market Research',
      desc: 'Experienced business and marketing professional, with over 12 years spent at the helm of ACFN’s Market Research department.',
      link: '',
    },
  ];

  const faqArr = [
    {
      question: 'How do I earn a return?',
      answer:
        'We are using Republics Crowd SAFE security. The full SAFE agreement with all the details is available for your review in the document section of the campaign page (top right side scroll down just a bit). When ACFN achieves a liquidity event as defined in the agreement (for example sale or IPO) you can choose between getting the cash value at that time or if you prefer you can get the shares. Completing successful acquisitions growing revenue meaningfully and profits exponentially increases the value of ACFN and creates shareholder value. We expect to grow from our current run rate of $15M/Yr to $50M/yr+ within 3 to 5 years. At that level we intend to pursue a liquidity event and expect to achieve that at a premium valuation meaningfully higher than the current valuation cap. Note that as a SAFE investor you have the benefit of a valuation cap that provides important protection from dilution',
    },
    {
      question: 'How did you come up with your valuation?',
      answer:
        'ACFN is a mature developed business with seasoned management, $15,000,000 in annual sales, cash flow positive ($613,000 in 2021 - see audited financials included in our offering documents)  and poised for rapid growth using a proven acquisition strategy favored by current market conditions. In the next 3 - 5 years we expect to grow revenue to $50,000,000+ and achieve a significantly higher market value creating meaningful value for shareholders. As soon as we reach $50M/Yr in revenue we expect to have attractive exit opportunities. At this level valuation is typically at a premium and we expect will be much higher than the current valuation cap creating substantial value for shareholders. ',
    },
    {
      question: 'What do the next 5 years look like for ACFN?',
      answer:
        'Our mission is to complete 3 – 5 acquisitions substantially growing corporate relationships, transaction volumes in our network and the size of our franchise community to support and absorb this growth. Revenue is estimated to grow to $50,000,000+. In addition to growing revenue we will continue to integrate additional high margin revenue streams as well as continue working to develop new products and services to diversify our value proposition to both our corporate clients and franchise owners. As soon as we grow our revenue to $50M+ we expect there will be attractive exit opportunities at a premium valuation creating and materializing value for shareholders.',
    },
    {
      question: 'Why should I invest in this company? What’s ACFN’s story?',
      answer:
        'ACFN is a mature, developed business with a seasoned management team and a long track record of success. Current market conditions favor ACFN’s low-cost franchise model, and our proven acquisition strategy offers the opportunity to benefit from market conditions and rapidly grow our business. With our experience and track record of success and current market conditions investing in ACFN involves much lower risk then your typical startup while still offering all the upside from rapid growth. Substantial revenue and earnings growth create value for shareholders.',
    },
    {
      question: 'How are you compensating your founding team?',
      answer:
        'Founders and all VPs are all earning better than market rate salaries and receiving performance based profit share bonuses at the end of each fiscal year. Founders and VPs also have incentives to reach a liquidity event that are fully aligned with shareholders. ',
    },
  ];
  const risksArr = [
    {
      question:
        'The Company’s management may have broad discretion in how the Company uses the net proceeds of the Offering.',
      answer:
        'Unless the Company has agreed to a specific use of the proceeds from the Offering, the Company’s management will have considerable discretion over the use of proceeds from the Offering. You may not have the opportunity, as part of your investment decision, to assess whether the proceeds are being used appropriately.',
    },
    {
      question:
        'Global crises and geopolitical events, including without limitation, COVID-19 can have a significant effect on our business operations and revenue projections.',
      answer:
        'With shelter-in-place orders and non-essential business closings potentially happening intermittently throughout 2022 and into the future due to COVID-19, the Company’s revenue may have been, and may continue to be, adversely affected.',
    },
    {
      question: 'We may face potential difficulties in obtaining capital.',
      answer:
        'We may have difficulty raising needed capital in the future as a result of, among other factors, our lack of revenues from sales, as well as the inherent business risks associated with our Company and present and future market conditions. We will require additional funds to execute our business strategy and conduct our operations. If adequate funds are unavailable, we may be required to delay, reduce the scope of or eliminate one or more of our research, development or commercialization programs, product launches or marketing efforts, any of which may materially harm our business, financial condition and results of operations.',
    },
    {
      question:
        'We may not have enough authorized capital stock to issue shares of common stock to investors upon the conversion of any security convertible into shares of our common stock, including the Securities.',
      answer:
        'Unless we increase our authorized capital stock, we may not have enough authorized common stock to be able to obtain funding by issuing shares of our common stock or securities convertible into shares of our common stock. We may also not have enough authorized capital stock to issue shares of common stock to investors upon the conversion of any security convertible into shares of our common stock, including the Securities.',
    },
    {
      question:
        'The Company has the right to limit individual Investor commitment amounts based on the Company’s determination of an Investor’s sophistication.',
      answer:
        'The Company may prevent any Investor from committing more than a certain amount in this Offering based on the Company’s determination of the Investor’s sophistication and ability to assume the risk of the investment. This means that your desired investment amount may be limited or lowered based solely on the Company’s determination and not in line with relevant investment limits set forth by the Regulation CF rules. This also means that other Investors may receive larger allocations of the Offering based solely on the Company’s determination.',
    },
  ];
  const allCategories = [
    'section-1',
    'section-2',
    'section-3',
    'section-4',
    'section-5',
    'section-6',
    'section-7',
    'section-8',
    'section-9',
    'section-10',
    'section-11',
    'section-12',
    'About',
    'Team',
    'Press',
    'Faq',
    'Risks',
  ];

  const scrollHandler = (_) => {
    // setShowPopup(false);
    // const catDiv = document.getElementById('catDiv');

    // const myDiv = document.getElementById("Blockchain");
    // const nextDiv = document.getElementById("Companies");
    // if the div reaches top of the screen set the selected search item to the title of the div

    if (allCategories.length > 0) {
      const lastTitle = allCategories[allCategories.length - 1];
      allCategories.map((item, index) => {
        let myDiv = document.getElementById(item);
        let nextDiv = document?.getElementById(allCategories[index + 1]);
        let prevDiv = document?.getElementById(allCategories[index - 1]);

        if (index < allCategories.length - 1) {
          if (
            myDiv?.getBoundingClientRect()?.top < 260 &&
            prevDiv?.getBoundingClientRect()?.top < 0
          ) {
            // console.log(index, "wekfhwef");
            setSelectedSectionId(item);
          } else if (
            myDiv?.getBoundingClientRect()?.top < 260 &&
            nextDiv?.getBoundingClientRect()?.top > 260
          ) {
            // console.log(index, "wekfhwef");
            setSelectedSectionId(item);
          }
        } else if (
          index === allCategories.length - 1 &&
          allCategories[allCategories.length - 1] === lastTitle
        ) {
          let myLastDiv = document?.getElementById(allCategories[index]);
          // console.log(myLastDiv, 'lwkenflkwnef');
          if (
            myLastDiv?.getBoundingClientRect()?.top < 260 &&
            nextDiv === null
          ) {
            // console.log(index, 'jhvkgjchg');
            setSelectedSectionId(allCategories[index]);
          }
          // setSelectedSectionId("Companies");
        }
      });
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', scrollHandler, scrollTo ? false : true);
    return () => {
      window.removeEventListener('scroll', scrollHandler, true);
    };
  }, [allCategories, scrollTo]);

  return (
    <>
      <div className={styles.templateContainer1}>
        <div className={styles.farLeftSide}>
          <div className={styles.menuContainer}>
            <div
              onClick={(e) => handleScroll('section-1')}
              className={
                selectedSectionId === 'section-1' ? styles.menuItem : ''
              }
            >
              Problem
            </div>
            <div
              onClick={(e) => handleScroll('section-2')}
              className={
                selectedSectionId === 'section-2' ? styles.menuItem : ''
              }
            >
              Solution
            </div>
            <div
              onClick={(e) => handleScroll('section-3')}
              className={
                selectedSectionId === 'section-3' ? styles.menuItem : ''
              }
            >
              Product
            </div>
            <div
              onClick={(e) => handleScroll('section-4')}
              className={
                selectedSectionId === 'section-4' ? styles.menuItem : ''
              }
            >
              Traction
            </div>
            <div
              onClick={(e) => handleScroll('section-5')}
              className={
                selectedSectionId === 'section-5' ? styles.menuItem : ''
              }
            >
              Customers
            </div>
            <div
              onClick={(e) => handleScroll('section-6')}
              className={
                selectedSectionId === 'section-6' ? styles.menuItem : ''
              }
            >
              Biz. model
            </div>
            <div
              onClick={(e) => handleScroll('section-7')}
              className={
                selectedSectionId === 'section-7' ? styles.menuItem : ''
              }
            >
              Market
            </div>
            <div
              onClick={(e) => handleScroll('section-8')}
              className={
                selectedSectionId === 'section-8' ? styles.menuItem : ''
              }
            >
              Competition
            </div>
            <div
              onClick={(e) => handleScroll('section-9')}
              className={
                selectedSectionId === 'section-9' ? styles.menuItem : ''
              }
            >
              Vision and strat...
            </div>
            <div>Impact</div>
            <div
              onClick={(e) => handleScroll('section-10')}
              className={
                selectedSectionId === 'section-10' ? styles.menuItem : ''
              }
            >
              Funding
            </div>
            <div
              onClick={(e) => handleScroll('section-11')}
              className={
                selectedSectionId === 'section-11' ? styles.menuItem : ''
              }
            >
              Founders
            </div>
            <div
              onClick={(e) => handleScroll('section-12')}
              className={
                selectedSectionId === 'section-12' ? styles.menuItem : ''
              }
            >
              Summary
            </div>
            <hr style={{ border: '0.5px solid #eee' }} />
            <div
              onClick={(e) => handleScroll('About')}
              className={selectedSectionId === 'About' ? styles.menuItem : ''}
            >
              About
            </div>
            <div
              onClick={(e) => handleScroll('Team')}
              className={selectedSectionId === 'Team' ? styles.menuItem : ''}
            >
              Team
            </div>
            <div
              onClick={(e) => handleScroll('Press')}
              className={selectedSectionId === 'Press' ? styles.menuItem : ''}
            >
              Press
            </div>
            <div
              onClick={(e) => handleScroll('Faq')}
              className={selectedSectionId === 'Faq' ? styles.menuItem : ''}
            >
              FAQ
            </div>
            <div
              onClick={(e) => handleScroll('Risks')}
              className={selectedSectionId === 'Risks' ? styles.menuItem : ''}
            >
              Risks
            </div>
            {/* <div onClick={(e) => handleScroll('section-18')}>Discussion</div> */}
          </div>
        </div>

        {/* mobile far left side start*/}
        <div className={styles.mobileFarLeftSide}>
          <select
            defaultValue="Pitch"
            className={styles.inputStyle}
            style={{}}
            value={selectedNav}
            onChange={(e) => setSelectedNav(e.target.value)}
          >
            {/* <option value="" selected disabled hidden>
            Select User Type
          </option> */}

            <option value={'Pitch'}>Pitch</option>
            <option value={'Deal Terms'}>Deal Terms</option>
            <option value={'Documents'}>Documents</option>
            <option value={'Perks'}>Perks</option>
            {/* <option value={"section-13"}>About</option> */}
            <option value={'Team'}>Team</option>
            <option value={'Press'}>Press</option>
            <option value={'Risks'}>Risks</option>
            <option value={'Discussion'}>Discussion (38)</option>
            <option value={'Updates'}>Updates (38)</option>
            <option value={'Reviews'}>Reviews (38)</option>
            <option value={'Faq'}>FAQ</option>
          </select>
          <div className={styles.iconsDivMobile}>
            <FiStar />
            <FiShare />
          </div>
        </div>
        {/* mobile far left side end*/}

        <div className={styles.leftSide}>
          <section style={{ paddingTop: '0px' }} id="Pitch">
            <div className={styles.sectionTitle}>Highlights</div>
            <hr className={styles.smallhr} />
            <div className={styles.vipCardContainer}>
              <div className={styles.vipCard}>
                <div className={styles.vipCardContent}>
                  <div>$10M revenue</div>
                  <div>
                    Company had over $10M in revenue in the past 12 months
                  </div>
                </div>
                <div className={styles.vipCardIcon}>
                  <img src={dollarIcon} alt="" />
                </div>
              </div>
              <div className={`${styles.vipCard} ${styles.tooltip}`}>
                <div className={styles.vipCardContent}>
                  <div>$1M+ raised</div>
                  <div>Company has previously raised over $1M in capital</div>
                </div>
                <div className={styles.vipCardIcon}>
                  <img src={arrowUp} alt="" />
                </div>
                <span className={styles.tooltiptext}>Tooltip text</span>
              </div>
            </div>
            <div>
              <ul className={styles.bulletPoints}>
                <li>
                  220 franchisees in 46 states serving 2700 corporate clients
                  nationwide
                </li>
                <li>Cash-flow positive with $15M+ in annual revenue</li>
                <li>
                  Award-winning & nationally recognized with Bloomberg TV
                  feature
                </li>
                <li>
                  Management team has 26 years of experience, 19 years in
                  franchising
                </li>
                <li>
                  Largest provider of ATM services to hotels in U.S. w/ 1800
                  hotel customers
                </li>
                <li>
                  Current market conditions w/ inflation & high fuel cost favor
                  ACFN’s model
                </li>
                <li>
                  Now scaling and acquiring competitors to achieve rapid growth
                </li>
              </ul>
            </div>
          </section>
          <section id="section-1">
            <div className={styles.sectionTitle}>Problem</div>
            <hr className={styles.smallhr} />
            <div style={{ width: '100%', paddingBottom: '30px' }}>
              <img src={img1} alt="" style={{ width: '100%' }} />
            </div>

            <div className={styles.contentText}>
              Millions of Americans have little free time outside of work for
              family and leisure, and yet not enough pay to live comfortably in
              the face of inflation and skyrocketing costs of living.
            </div>
            <div className={styles.contentText}>
              An increasing number of entrepreneurial spirits are looking for a
              smarter, more sustainable way to work and live. Starting your own
              business is one way to achieve that but comes with substantial
              risks…
            </div>
            <div style={{ width: '100%', paddingBottom: '30px' }}>
              <img src={img2} alt="" style={{ width: '100%' }} />
            </div>
          </section>
          <section id="section-2">
            <div className={styles.sectionTitle}>Solution</div>
            <hr className={styles.smallhr} />
            <div style={{ width: '100%', paddingBottom: '30px' }}>
              <img src={img3} alt="" style={{ width: '100%' }} />
            </div>
            <div className={styles.contentText}>
              ACFN (The American Consumer Financial Network) offers prospective
              entrepreneurs a unique part-time, low-cost, low-risk high-reward
              opportunity.
            </div>
            <div className={styles.contentText}>
              ACFN franchisees benefit from 19 years of corporate relationship
              building and a reputation for excellence. Franchisees enjoy
              semi-passive residual income, earned on flexible, part-time work
              hours—all owner-operated from home with no overhead costs.
            </div>
            <div className={styles.contentText}>
              By starting a franchise with ACFN’s proven recurring revenue
              model, the American Dream of more money for less work is within
              reach.
            </div>
            <div style={{ width: '100%', paddingBottom: '30px' }}>
              <img src={img4} alt="" style={{ width: '100%' }} />
            </div>
          </section>
          <section id="section-3">
            <div className={styles.sectionTitle}>Product</div>
            <hr className={styles.smallhr} />
            <div style={{ width: '100%', paddingBottom: '30px' }}>
              <img src={img5} alt="" style={{ width: '100%' }} />
            </div>
            <div className={styles.contentText}>
              Founded in 1996 and franchised in 2003, ACFN Franchised Inc.
              (“ACFN”) provides services to 2,700+ businesses in 46 states in
              collaboration with our network of 220+ franchise owners.
            </div>
            <div className={styles.contentText}>
              Since inception, ACFN has provided a cumulative $5,000,000,000 of
              spending power to support and increase sales for our partner
              businesses. In just the last 12 months a total of $367,566,000 was
              dispensed through our network, generating more than $14,500,000 in
              fee revenue for ACFN.
            </div>
            <div className={styles.contentText}>
              For ACFN’s corporate customers, the value of our turnkey and
              hassle-free ATM services cannot be overstated.
            </div>
            <div style={{ width: '100%', paddingBottom: '30px' }}>
              <img src={img6} alt="" style={{ width: '100%' }} />
            </div>
          </section>
          <section id="section-4">
            <div className={styles.sectionTitle}>Traction</div>
            <hr className={styles.smallhr} />
            <div style={{ width: '100%', paddingBottom: '30px' }}>
              <img src={img7} alt="" style={{ width: '100%' }} />
            </div>
            <div className={styles.contentText}>
              With over 26 years of industry experience providing turnkey ATM
              services and 19 years of franchising, ACFN has a well-deserved
              reputation for excellence amongst both our corporate partners and
              our franchisees.
            </div>
            <h3>Entrepreneur Magazine distinctions:</h3>
            <ul className={styles.bulletPoints}>
              <li>Top Part-Time Franchise, 2021</li>
              <li>Top Low-Cost Franchise, 2020 </li>
              <li>Fastest Growing Franchise, 2020</li>
              <li>#1 Franchise in category, 2018 & 2019</li>
              <li>Ranked Franchise 500 listing, 2020 & 2019</li>
              <li>Top Franchise for Veterans, 2018 & 2017</li>
            </ul>
            <div style={{ width: '100%', paddingBottom: '30px' }}>
              <img src={img8} alt="" style={{ width: '100%' }} />
            </div>
          </section>
          <section id="section-5">
            <div className={styles.sectionTitle}>Customers</div>
            <hr className={styles.smallhr} />
            <div style={{ width: '100%', paddingBottom: '30px' }}>
              <img src={img9} alt="" style={{ width: '100%' }} />
            </div>
            <div className={styles.contentText}>
              As the largest ATM provider to U.S. hotels, ACFN’s product suite
              of turnkey ATM services—plus our recently expanded offerings of
              cell phone charging stations, and pharmacy vending machines—helps
              our business clients increase sales, add new revenue streams, and
              better serve their customers.
            </div>
            <div className={styles.contentText}>
              Since our inception in 2002, ACFN has provided a cumulative $5
              billion in on-property spending power to support and increase
              sales for our partner businesses, which include hotels, malls,
              hospitals, universities, entertainment venues, and other travel
              and entertainment businesses.
            </div>
            <div style={{ width: '100%', paddingBottom: '30px' }}>
              <img src={img10} alt="" style={{ width: '100%' }} />
            </div>
            <div style={{ width: '100%', paddingBottom: '30px' }}>
              <img src={img11} alt="" style={{ width: '100%' }} />
            </div>
          </section>
          <section id="section-6">
            <div className={styles.sectionTitle}>Business model</div>
            <hr className={styles.smallhr} />
            <div style={{ width: '100%', paddingBottom: '30px' }}>
              <img src={img12} alt="" style={{ width: '100%' }} />
            </div>
            <div className={styles.contentText}>
              In just the last 12 months, over $360,000,000 in cash was
              dispensed through our ATM network, generating more than
              $14,500,000 in annual revenue for ACFN.
            </div>
            <div className={styles.contentText}>
              Our business model features several recurring revenue streams:
            </div>
            <ul className={styles.bulletPoints}>
              <li>Interchange, earned from each transaction</li>
              <li>Admin fees, paid from each ATM location each month</li>
              <li>Transaction fees, paid from each surcharge transaction</li>
              <li>Virtual office fee, paid from each franchisee each month</li>
              <li>
                Currency exchange, earned from withdrawals by international card
                holders
              </li>
              <li>
                Franchise fees, paid by each new franchisee joining our network
              </li>
              <li>
                Equipment sales, earned from each ATM purchased by franchise
                owners
              </li>
            </ul>
            <div style={{ width: '100%', paddingBottom: '30px' }}>
              <img src={img13} alt="" style={{ width: '100%' }} />
            </div>
            <div style={{ width: '100%', paddingBottom: '30px' }}>
              <img src={img14} alt="" style={{ width: '100%' }} />
            </div>
          </section>
          <section id="section-7">
            <div className={styles.sectionTitle}>Market</div>
            <hr className={styles.smallhr} />
            <div style={{ width: '100%', paddingBottom: '30px' }}>
              <img src={img15} alt="" style={{ width: '100%' }} />
            </div>
            <div className={styles.contentText}>
              ACFN started in 2002 and franchised in 2003—which means we’ve
              survived the 2000 dot-com bubble burst, 9/11, the 2008 financial
              recession, and most recently, the last two years of a global
              pandemic.
            </div>
            <div className={styles.contentText}>
              While other business models are bracing for tough times in the
              current economic climate, global inflation and high fuel costs
              actually favor ACFN’s low-cost franchise model that requires
              little to no overhead, staffing, or operational costs.
            </div>
            <div style={{ width: '100%', paddingBottom: '30px' }}>
              <img src={img16} alt="" style={{ width: '100%' }} />
            </div>
          </section>
          <section id="section-8">
            <div className={styles.sectionTitle}>Competition</div>
            <hr className={styles.smallhr} />
            <div style={{ width: '100%', paddingBottom: '30px' }}>
              <img src={img17} alt="" style={{ width: '100%' }} />
            </div>
            <div className={styles.contentText}>
              ACFN is the only franchise model in our industry. Our competitors
              operate a traditional corporate structure and outsource to third
              parties. Record increases in wages and fuel expenses have
              increased costs and reduced margins for competitors. Our model
              enables franchisees to serve customers much more efficiently. With
              relatively limited expenses and close proximity to business
              clients (which limits the impact of fuel costs), ACFN is well
              positioned to benefit from current market conditions…
            </div>
            <div className={styles.contentText}>
              We believe this “perfect storm” of wage increases and high fuel
              costs will drive competitors to look for an exit. With these
              acquisition opportunities, ACFN will consolidate the industry,
              scale our business, reduce competition, and improve margins.
            </div>
            <div style={{ width: '100%', paddingBottom: '30px' }}>
              <img src={img18} alt="" style={{ width: '100%' }} />
            </div>
          </section>
          <section id="section-9">
            <div className={styles.sectionTitle}>Vision and strategy</div>
            <hr className={styles.smallhr} />
            <div style={{ width: '100%', paddingBottom: '30px' }}>
              <img src={img19} alt="" style={{ width: '100%' }} />
            </div>
            <div className={styles.contentText}>
              Our mission is to achieve rapid growth via acquisitions to expand
              our corporate relationships, increase the size of our franchise
              network, and increase transaction volume.
            </div>
            <div className={styles.contentText}>
              ACFN has already completed two acquisitions in 2018 and 2020. Both
              were funded privately with debt and resulted in a 26.5% increase
              in transaction volume.
            </div>
            <div className={styles.contentText}>
              Acquisitions are the primary reason for our capital raise and a
              key component of ACFN’s strategy to grow revenue via economies of
              scale, operational efficiencies, and reduced competition.
            </div>
            <div style={{ width: '100%', paddingBottom: '30px' }}>
              <img src={img20} alt="" style={{ width: '100%' }} />
            </div>
            <div className={styles.contentText} style={{ fontWeight: 600 }}>
              Diversification:
            </div>
            <div className={styles.contentText}>
              ACFN is diversifying with two new products: cell phone charging
              stations and digital pharmacies. These new offerings will leverage
              our network to better serve our corporate partners and create
              cross-selling opportunities.
            </div>
            <div style={{ width: '100%', paddingBottom: '30px' }}>
              <img src={img21} alt="" style={{ width: '100%' }} />
            </div>
          </section>
          <section id="section-10">
            <div className={styles.sectionTitle}>Funding</div>
            <hr className={styles.smallhr} />
            <div style={{ width: '100%', paddingBottom: '30px' }}>
              <img src={img22} alt="" style={{ width: '100%' }} />
            </div>
            <div className={styles.contentText}>
              Through founder Jeff Kerr’s personal capital and prior business
              income, ACFN was successfully bootstrapped to a self-sustaining
              revenue model early on.
            </div>

            <div className={styles.contentText}>
              In 2018, ACFN accepted outside investment for the first time,
              loaning $2.25M from a noted investor to finance two successful
              acquisitions for $3.5M and $2.95M.
            </div>
            <div className={styles.contentText}>
              The rest came from personal savings, modest friends and family
              loans, and ACFN’s corporate resources. All principle and interest
              has since been paid back in full.
            </div>
            <div style={{ width: '100%', paddingBottom: '30px' }}>
              <img src={img23} alt="" style={{ width: '100%' }} />
            </div>
          </section>
          <section id="section-11">
            <div className={styles.sectionTitle}>Founders</div>
            <hr className={styles.smallhr} />
            <div style={{ width: '100%', paddingBottom: '30px' }}>
              <img src={img24} alt="" style={{ width: '100%' }} />
            </div>
            <div className={styles.contentText}>
              In the 1990s, ACFN founder Jeff Kerr was running a successful
              business, but working very long hours. With a desire to have both
              financial success and a good quality of life, he began
              investigating opportunities to build semi-passive income.
            </div>
            <div className={styles.contentText}>
              In 1996, Jeff found himself in the audience at a conference
              presentation for a new business opportunity: to own and operate
              private ATMs. Regulations governing ATM transactions had just
              changed to allow surcharging and for Jeff, it was love at first
              sight: the inception of private ATM ownership. 53 ATMs later, his
              side venture outpaced his main earnings with only a fraction of
              demand on his time.
            </div>
            <div style={{ width: '100%', paddingBottom: '30px' }}>
              <img src={img25} alt="" style={{ width: '100%' }} />
            </div>
          </section>
          <section id="section-12">
            <div className={styles.sectionTitle}>Summary</div>
            <hr className={styles.smallhr} />
            <div style={{ width: '100%', paddingBottom: '30px' }}>
              <img src={img26} alt="" style={{ width: '100%' }} />
            </div>
            <div className={styles.contentText}>
              ACFN is an innovative franchise model for entrepreneurial
              individuals interested in owning a franchise, but not interested
              in the typical retail franchise with long hours, high capital
              investment, and high risk associated with high overhead. Our
              innovative franchise model is part time with flexible hours, low
              capital requirements, and stable recurring revenue streams.
            </div>
            <div className={styles.contentText}>
              As the only franchisor in our industry, ACFN is the low-cost
              leader with multiple sustainable competitive advantages. Our
              experienced team oversees a proven business model with targeted
              plans to scale: ACFN has already completed two acquisitions with
              three more distinct opportunities for substantial revenue growth
              currently in various stages of discussion.
            </div>
            <div style={{ width: '100%', paddingBottom: '30px' }}>
              <img src={img27} alt="" style={{ width: '100%' }} />
            </div>
          </section>
          <section id="About">
            <div className={styles.aboutCompany}>
              <div className={styles.bottomTitle}>
                About ACFN Franchised Inc.
              </div>
              <hr className={styles.blueBar} />
            </div>
            <div className={styles.aboutContent}>
              <div>
                <div>
                  <div className={styles.labelName}>Legal Name</div>
                  <div className={styles.labelData}>ACFN Franchised Inc.</div>
                </div>
                <div>
                  <div className={styles.labelName}>Founded</div>
                  <div className={styles.labelData}>Oct 2002</div>
                </div>
                <div>
                  <div className={styles.labelName}>Form</div>
                  <div className={styles.labelData}>California Corporation</div>
                </div>
              </div>
              <div>
                <div>
                  <div className={styles.labelName}>Employees</div>
                  <div className={styles.labelData}>18</div>
                </div>
                <div>
                  <div className={styles.labelName}>Website</div>
                  <div className={styles.labelData}>atmfranchise.com</div>
                </div>
                <div>
                  <div className={styles.labelName}>Social Media</div>
                  <div className={styles.labelData}>California Corporation</div>
                </div>
              </div>
              <div>
                <div className={styles.labelName}>Headquarters</div>
                <div>
                  <img src={staticmap} alt="" width={'100%'} height={'100%'} />
                </div>
              </div>
            </div>
          </section>
          <section id="Team">
            <div className={styles.aboutCompany}>
              <div className={styles.bottomTitle}>
                ACFN Franchised Inc. Team
              </div>
              <div
                style={{
                  paddingBottom: '10px',
                  fontSize: '14px',
                  color: '#999',
                }}
              >
                Everyone helping build ACFN Franchised Inc., not limited to
                employees
              </div>
              <hr className={styles.blueBar} />

              <div className={styles.teamWrapper}>
                {teamMembers.map((item, index) => {
                  return (
                    <div className={styles.teamItem}>
                      <img
                        src={require(`../../../static/images/templateImages/avatar${
                          index + 1
                        }.webp`)}
                        alt=""
                        style={{ width: '125px', height: '125px' }}
                      />
                      <div
                        className={styles.labelData}
                        style={{ padding: '10px 0px' }}
                      >
                        {item.name}
                      </div>
                      <div className={styles.labelName}>{item.designation}</div>
                      <div
                        className={styles.labelName}
                        style={{ fontSize: '12px' }}
                      >
                        {item.desc}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </section>
          <section id="Press">
            <div className={styles.aboutCompany}>
              <div className={styles.bottomTitle}>Press</div>
              <hr className={styles.blueBar} style={{ width: '50px' }} />
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginBottom: '50px',
              }}
            >
              <div
                className={styles.vipCard}
                style={{ width: '500px', height: '400px' }}
              >
                <ReactPlayer
                  url="https://www.youtube.com/watch?v=AclN7SQUr0U"
                  // url="https://uploads.republic.com/p/offerings/slider_media_items/contents/original/000/006/908/6908-1667224133-0b863aba930ba00590169cf9195bf5cda38701ab.mp4"
                  width={'100%'}
                  // height={'100%'}
                />
              </div>
            </div>
          </section>
          <section id="Faq">
            <div className={styles.aboutCompany}>
              <div className={styles.bottomTitle}>FAQ</div>
              <hr className={styles.blueBar} style={{ width: '50px' }} />
            </div>
            <div className={styles.faqContainer}>
              <div>
                {faqArr.map((item, index) => {
                  return (
                    <div
                      style={{
                        color:
                          selectedFaq?.question === item.question
                            ? '#0049ff'
                            : '',
                      }}
                      className={styles.questionItem}
                      onClick={(e) => setSelectedFaq(item)}
                    >
                      {item?.question}
                    </div>
                  );
                })}
              </div>
              <div className={styles.answerWrapper}>
                <div className={styles.answerHeader}>
                  {selectedFaq?.question}
                </div>
                <div className={styles.answerText}>
                  {selectedFaq ? selectedFaq.answer : faqArr[0].answer}
                </div>
              </div>
            </div>
          </section>
          <section id="Risks">
            <div className={styles.aboutCompany} style={{ marginTop: '50px' }}>
              <div className={styles.bottomTitle}>Risks</div>
              <hr className={styles.blueBar} style={{ width: '50px' }} />
            </div>
            <div className={styles.faqContainer}>
              <div>
                {risksArr.map((item, index) => {
                  return (
                    <div
                      style={{
                        color:
                          selectedRisk?.question === item.question
                            ? '#0049ff'
                            : '',
                      }}
                      className={styles.questionItem}
                      onClick={(e) => setSelectedRisk(item)}
                    >
                      {item?.question}
                    </div>
                  );
                })}
              </div>
              <div className={styles.riskAnswerWrapper}>
                <div className={styles.answerText}>
                  {selectedRisk ? selectedRisk.answer : risksArr[0].answer}
                </div>
              </div>
            </div>
          </section>
        </div>
        {/* <div className={styles.rightSide}>
          <section style={{ paddingTop: '0px' }} id="Deal Terms">
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div className={styles.sectionTitle}>Deal terms</div>
              <Popup
                modal
                trigger={
                  <div
                    className={styles.badgeSpecial}
                    onClick={(e) => setShowPopup(true)}
                  >
                    <IoIosFlash width={20} height={20} />
                    &nbsp;&nbsp;SPECIAL
                  </div>
                }
                position="right center"
              >
                <PopupSpecial setShowPopup={setShowPopup} />
              </Popup>
            </div>
            <hr className={styles.smallhr} />
          </section>
          <section style={{ paddingTop: '0px' }}>
            <div
              className={styles.termWrapper}
              onMouseLeave={(e) => {
                setTimeout(() => {
                  setShowDesc('');
                }, 1000);
              }}
            >
              <div
                className={styles.termItem}
                onMouseEnter={(e) => setShowDesc('Valuation cap')}
              >
                <div>Valuation cap</div>
                <div
                  style={{
                    color: '#F83A5C',
                    display: 'flex',
                    alignItems: 'center',
                    fontWeight: '600',
                  }}
                >
                  <IoIosFlash width={20} height={20} />{' '}
                  <span>$28M&nbsp;&nbsp;</span>
                  <span
                    style={{
                      textDecoration: 'line-through',
                      color: 'black',
                    }}
                  >
                    $35M
                  </span>
                </div>
              </div>
              <div
                className={styles.termDesc}
                style={{
                  display: showDesc == 'Valuation cap' ? 'block' : 'none',
                }}
              >
                The maximum valuation at which your investment converts into
                equity shares or cash.
                <div className={styles.learnMore}>Learn more.</div>
              </div>
            </div>
            <div
              className={styles.termWrapper}
              onMouseLeave={(e) => {
                setTimeout(() => {
                  setShowDesc('');
                }, 1000);
              }}
            >
              <div
                className={styles.termItem}
                onMouseEnter={(e) => setShowDesc('Funding goal')}
              >
                <div>Funding goal</div>
                <div
                  style={{
                    color: 'black',
                    display: 'flex',
                    alignItems: 'center',
                    fontWeight: '600',
                  }}
                >
                  $25K / $5M
                </div>
              </div>
              <div
                className={styles.termDesc}
                style={{
                  display: showDesc == 'Funding goal' ? 'block' : 'none',
                }}
              >
                ACFN Franchised Inc. needs to raise $25K before the deadline.
                The maximum amount ACFN Franchised Inc. is willing to raise is
                $5M.
                <div className={styles.learnMore}>Learn more.</div>
              </div>
            </div>
            <div
              className={styles.termWrapper}
              onMouseLeave={(e) => {
                setTimeout(() => {
                  setShowDesc('');
                }, 1000);
              }}
            >
              <div
                className={styles.termItem}
                onMouseEnter={(e) => setShowDesc('Security type')}
              >
                <div>Security type</div>
                <div
                  style={{
                    color: 'black',
                    display: 'flex',
                    alignItems: 'center',
                    fontWeight: '600',
                  }}
                >
                  Crowd SAFE
                </div>
              </div>
              <div
                className={styles.termDesc}
                style={{
                  display: showDesc == 'Security type' ? 'block' : 'none',
                }}
              >
                A SAFE allows an investor to make a cash investment in a
                company, with rights to receive certain company stock at a later
                date, in connection with a specific event.
                <div className={styles.learnMore}>Learn more.</div>
              </div>
            </div>
            <div
              className={styles.termWrapper}
              onMouseLeave={(e) => {
                setTimeout(() => {
                  setShowDesc('');
                }, 1000);
              }}
            >
              <div
                className={styles.termItem}
                onMouseEnter={(e) => setShowDesc('Deadline')}
              >
                <div>Deadline</div>
                <div
                  style={{
                    color: 'black',
                    display: 'flex',
                    alignItems: 'center',
                    fontWeight: '600',
                  }}
                >
                  April 21, 2023
                </div>
              </div>
              <div
                className={styles.termDesc}
                style={{
                  display: showDesc == 'Deadline' ? 'block' : 'none',
                }}
              >
                ACFN Franchised Inc. needs to reach their minimum funding goal
                before the deadline (April 21, 2023 at 12:29 PM IST). If they
                don’t, all investments will be refunded.
                <div className={styles.learnMore}>Learn more.</div>
              </div>
            </div>
            <div
              className={styles.termWrapper}
              onMouseLeave={(e) => {
                setTimeout(() => {
                  setShowDesc('');
                }, 1000);
              }}
            >
              <div
                className={styles.termItem}
                onMouseEnter={(e) => setShowDesc('Minimum investment')}
              >
                <div>Minimum investment</div>
                <div
                  style={{
                    color: 'black',
                    display: 'flex',
                    alignItems: 'center',
                    fontWeight: '600',
                  }}
                >
                  $150
                </div>
              </div>
              <div
                className={styles.termDesc}
                style={{
                  display: showDesc == 'Minimum investment' ? 'block' : 'none',
                }}
              >
                The smallest investment amount that ACFN Franchised Inc. is
                accepting.
                <div className={styles.learnMore}>Learn more.</div>
              </div>
            </div>
            <div className={styles.howItWorksBtn}>How it works</div>
          </section>
          <section id="Documents">
            <div
              className={styles.sectionTitle}
              style={{ paddingBottom: '20px' }}
            >
              Documents
            </div>
            <div className={styles.docWrapper}>
              <div className={styles.docDesc}>
                Republic (OpenDeal Portal LLC, CRD #283874) is hosting this Reg
                CF securities offering by ACFN Franchised Inc.. View the
                official SEC filing and all updates:
              </div>
              <div className={styles.secLink}>
                <img
                  src="https://assets.republic.com/assets/sec_logo-d54253528cd94c8c0b42929c0b8af85bee044c658a78a4446cce43336cc52b1f.png"
                  alt=""
                  style={{ width: '40px', height: '40px' }}
                />
                <div style={{ paddingLeft: '10px' }}>Form C</div>
                <div style={{ paddingLeft: '5px', opacity: '0.5' }}>
                  | SEC.gov
                </div>
              </div>
              <div style={{ padding: '16px' }}>
                <div>Company documents</div>
                <br />
                <div className={styles.docContainer}>
                  <GrDocumentText style={{ fontSize: '30px' }} />
                  <div style={{ paddingLeft: '10px' }}>
                    ACFN Franchised Inc. Crowd SAFE
                  </div>
                </div>
                <div className={styles.docContainer}>
                  <GrDocumentText style={{ fontSize: '30px' }} />
                  <div style={{ paddingLeft: '10px' }}>
                    ACFN Franchised Inc. Crowd SAFE
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="Perks">
            <div
              className={styles.sectionTitle}
              style={{ paddingBottom: '20px' }}
            >
              Bonus perks
            </div>
            <div
              style={{
                paddingBottom: '20px',
                lineHeight: '1.4',
                fontSize: '16px',
              }}
            >
              In addition to your Crowd SAFE, you'll receive perks for investing
              in ACFN Franchised Inc..
            </div>
            <div className={styles.docWrapper}>
              <div className={styles.investCard}>
                <div className={styles.investLable}>Invest</div>
                <div className={styles.amountStyle}>$2,500</div>
                <div className={styles.investLable}>Receive</div>
                <ul className={styles.benefitList}>
                  <li>Receive $2,500 credit towards franchise cost</li>
                  <li>
                    Credit valid for 12 months from the date of Investment
                  </li>
                </ul>
                <div className={styles.howItWorksBtn}>Invest $2,500</div>
              </div>
              <div className={styles.investCard}>
                <div className={styles.investLable}>Invest</div>
                <div className={styles.amountStyle}>$5,000</div>
                <div className={styles.investLable}>Receive</div>
                <ul className={styles.benefitList}>
                  <li>Receive $5,000 credit towards franchise cost</li>
                  <li>
                    Credit valid for 12 months from the date of Investment
                  </li>
                </ul>
                <div className={styles.howItWorksBtn}>Invest $5,000</div>
              </div>
              <div className={styles.investCard}>
                <div className={styles.investLable}>Invest</div>
                <div className={styles.amountStyle}>$10,000</div>
                <div className={styles.investLable}>Receive</div>
                <ul className={styles.benefitList}>
                  <li>Receive $10,000 credit towards franchise cost</li>
                  <li>
                    Credit valid for 12 months from the date of Investment
                  </li>
                </ul>
                <div className={styles.howItWorksBtn}>Invest $10,000</div>
              </div>
              <div className={styles.investCard}>
                <div className={styles.investLable}>Invest</div>
                <div className={styles.amountStyle}>$15,000</div>
                <div className={styles.investLable}>Receive</div>
                <ul className={styles.benefitList}>
                  <li>Receive $15,000 credit towards franchise cost</li>
                  <li>
                    Credit valid for 12 months from the date of Investment
                  </li>
                </ul>
                <div className={styles.howItWorksBtn}>Invest $15,000</div>
              </div>
              <div className={styles.investCard}>
                <div className={styles.investLable}>Invest</div>
                <div className={styles.amountStyle}>$25,000</div>
                <div className={styles.investLable}>Receive</div>
                <ul className={styles.benefitList}>
                  <li>Receive $25,000 credit towards franchise cost</li>
                  <li>
                    Credit valid for 12 months from the date of Investment
                  </li>
                </ul>
                <div className={styles.howItWorksBtn}>Invest $25,000</div>
              </div>
            </div>
          </section>
        </div> */}
      </div>
    </>
  );
};

export default Pitch;

const PopupSpecial = (setShowPopup) => {
  return (
    <div className={styles.modalStyle}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div style={{ width: '30px', height: '30px', background: '#e7e7e7' }}>
          <img
            src="https://uploads.republic.com/p/offerings/logos/small_2x/000/001/825/1825-1663866126-7f54924d96c9f970d76dc1790e0e5513cc19ff5f.png"
            alt=""
            style={{ width: '100%', height: '100%', borderRadius: '5px' }}
          />
        </div>
        <div className={styles.headerTitle} style={{ fontSize: '30px' }}>
          ACFN Franchised Inc.
        </div>
      </div>
      <div
        className={styles.headerSubTitle}
        style={{ textAlign: 'center', width: '100%', padding: '20px 0px' }}
      >
        Creating opportunities for entrepreneurs | Hundreds of franchises to
        date
      </div>
      <div
        className={styles.tableStyle}
        style={{ fontWeight: 'bold', paddingTop: '30px' }}
      >
        <div>Raised amount</div>
        <div>Valuation cap</div>
      </div>
      <div className={styles.tableStyle}>
        <div>0 — $1,000,000</div>
        <div>$28M</div>
      </div>
      <div className={styles.tableStyle}>
        <div>$1,000,000.01 — $5,000,000</div>
        <div>$35M</div>
      </div>
      <hr style={{ border: '0.5px solid #eee', margin: '10px 0px' }} />
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div
          className={styles.howItWorksBtn}
          style={{ width: '200px' }}
          onClick={(e) => setShowPopup(false)}
        >
          Close
        </div>
      </div>
    </div>
  );
};
