import React, { useContext, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import BusinessPage from './pages/businessPage';
import LandingPage from './pages/LandingPageNew';
import { MOBILE_SIZE } from './config';
import useWindowDimensions from './utilsold/WindowSize';
import MobileLandingPage from './components/MobileLayout/MobileLandingPage';
import BondsPage from './pages/ProductsPage/Bonds';
import BondOverview from './pages/BondOverview/BondOverview';
import ProductsNew from './pages/ProductsPageNew';
import DirectoryPage from './pages/DirectoryPage';
import VpTemplate from './pages/VpTemplate';
import EducationPage from './pages/EducationPage';
import { useLocation } from 'react-router-dom';
import { GlobalContext } from './components/context/globalcontext';
import Impage from './pages/ImPage';
import RootFile from './pages/RootFile';
const Routess = () => {
  const { width } = useWindowDimensions();
  const { setnavItemClicked } = useContext(GlobalContext);
  let location = useLocation();

  useEffect(() => {
    console.log('location', location.pathname);
    if (location.pathname == '/im' || location.pathname == '/im/directory') {
      setnavItemClicked('im');
    } else if (location.pathname == '/') {
      setnavItemClicked('home');
    }
    // setCurrentPath(location.pathname);
  }, [location]);
  return (
    <>
      <Routes>
        {width > MOBILE_SIZE ? (
          // <Route exact path="/" element={<LandingPage />} />

          <Route exact path="/" element={<LandingPage />} />
        ) : (
          <Route exact path="/" element={<MobileLandingPage />} />
        )}
        <Route exact path="/legacy" element={<RootFile />} />
        <Route path="/products/companies" element={<BusinessPage />} />
        <Route path="/products/investors" element={<ProductsNew />} />
        <Route path="/im" element={<Impage />} />
        <Route path="/im/directory" element={<DirectoryPage />} />
        <Route path="/vp/template" element={<VpTemplate />} />
        <Route path="/react/education" element={<EducationPage />} />
      </Routes>
    </>
  );
};

export default Routess;
