import React, { useState } from 'react';
import classNames from './directory.module.scss';
import { AiOutlineDown } from 'react-icons/ai';
import taxchains from '../../assets/logos/halfcircle/taxchains.svg';

const mapItems = [
  'Overview',
  'Executives',
  'Investors',
  'Directors',
  'Financials',
];

const mapDataItems = [
  {
    company: 'TaxChains',
    icon: taxchains,
    description:
      'Twitter is a social networking platform that allows its users to send and read micro-blogs of up to 280-characters ',
    industries: ['Accounting', 'Taxes', 'Crypto'],
    founders: ['Shorupan Pirakaspathy', 'Nithya Reddy'],
    location: ['Hyderabad', 'Telangana'],
    status: 'Idea',
  },
  {
    company: 'TaxChains',
    icon: taxchains,
    description:
      'Twitter is a social networking platform that allows its users to send and read micro-blogs of up to 280-characters ',
    industries: ['Accounting', 'Taxes', 'Crypto'],
    founders: ['Shorupan Pirakaspathy', 'Nithya Reddy'],
    location: ['Hyderabad', 'Telangana'],
    status: 'Seed',
  },
  {
    company: 'TaxChains',
    icon: taxchains,
    description:
      'Twitter is a social networking platform that allows its users to send and read micro-blogs of up to 280-characters ',
    industries: ['Accounting', 'Taxes', 'Crypto'],
    founders: ['Shorupan Pirakaspathy', 'Nithya Reddy'],
    location: ['Hyderabad', 'Telangana'],
    status: 'Series B',
  },
  {
    company: 'TaxChains',
    icon: taxchains,
    description:
      'Twitter is a social networking platform that allows its users to send and read micro-blogs of up to 280-characters ',
    industries: ['Accounting', 'Taxes', 'Crypto'],
    founders: ['Shorupan Pirakaspathy', 'Nithya Reddy'],
    location: ['Hyderabad', 'Telangana'],
    status: 'Acquired',
  },
  {
    company: 'TaxChains',
    icon: taxchains,
    description:
      'Twitter is a social networking platform that allows its users to send and read micro-blogs of up to 280-characters ',
    industries: ['Accounting', 'Taxes', 'Crypto'],
    founders: ['Shorupan Pirakaspathy', 'Nithya Reddy'],
    location: ['Hyderabad', 'Telangana'],
    status: 'Dissolved',
  },
  {
    company: 'TaxChains',
    icon: taxchains,
    description:
      'Twitter is a social networking platform that allows its users to send and read micro-blogs of up to 280-characters ',
    industries: ['Accounting', 'Taxes', 'Crypto'],
    founders: ['Shorupan Pirakaspathy', 'Nithya Reddy'],
    location: ['Hyderabad', 'Telangana'],
    status: 'Series F',
  },
  {
    company: 'TaxChains',
    icon: taxchains,
    description:
      'Twitter is a social networking platform that allows its users to send and read micro-blogs of up to 280-characters ',
    industries: ['Accounting', 'Taxes', 'Crypto'],
    founders: ['Shorupan Pirakaspathy', 'Nithya Reddy'],
    location: ['Hyderabad', 'Telangana'],
    status: 'Series F',
  },
  {
    company: 'TaxChains',
    icon: taxchains,
    description:
      'Twitter is a social networking platform that allows its users to send and read micro-blogs of up to 280-characters ',
    industries: ['Accounting', 'Taxes', 'Crypto'],
    founders: ['Shorupan Pirakaspathy', 'Nithya Reddy'],
    location: ['Hyderabad', 'Telangana'],
    status: 'Series F',
  },
  {
    company: 'TaxChains',
    icon: taxchains,
    description:
      'Twitter is a social networking platform that allows its users to send and read micro-blogs of up to 280-characters ',
    industries: ['Accounting', 'Taxes', 'Crypto'],
    founders: ['Shorupan Pirakaspathy', 'Nithya Reddy'],
    location: ['Hyderabad', 'Telangana'],
    status: 'Series F',
  },
];

const Directory = () => {
  const [selectedOption, setSelectedOption] = useState('companies');
  const [mapItemFiltered, setmapItemFiltered] = useState([
    'Overview',
    'Executives',
    'Investors',
    'Directors',
    'Financials',
  ]);

  function filterSort(searchText) {
    let item = mapItems.filter((eachItem) => {
      let eachitem = eachItem.toLowerCase();
      // console.log('filterrr', eachitem, searchText);
      return eachitem.includes(searchText?.toLowerCase());
    });
    setmapItemFiltered(item);
  }

  function uncheckAll() {
    document
      .querySelectorAll('input[type="checkbox"]')
      .forEach((el) => (el.checked = false));
  }

  return (
    <div className={classNames.directoryContainer}>
      <div className={classNames.directoryTopContainer}>
        {/* <div className={classNames.title}>The Indian Market</div> */}
        <div className={classNames.optionsContainer}>
          <div
            className={selectedOption == 'companies' ? classNames.selected : ''}
            onClick={() => setSelectedOption('companies')}
          >
            Companies
          </div>
          <div
            className={
              selectedOption == 'realestate' ? classNames.selected : ''
            }
            onClick={() => setSelectedOption('realestate')}
          >
            Real Estate
          </div>
          <div
            className={selectedOption == 'charities' ? classNames.selected : ''}
            onClick={() => setSelectedOption('charities')}
          >
            Charities
          </div>
        </div>
      </div>
      <div className={classNames.directoryBottomContainer}>
        <div className={classNames.leftContainer}>
          <div className={classNames.title}>
            <div>Filters</div>
            <div>Clear</div>
          </div>
          <div className={classNames.searchDiv}>
            <input
              type="text"
              placeholder="Search..."
              onChange={(event) => filterSort(event.target.value)}
            />
          </div>
          <div className={classNames.filterItems}>
            {mapItemFiltered?.map((eachItem) => {
              return <SingleFilterItem title={eachItem} />;
            })}
          </div>
        </div>
        <div className={classNames.rightContainer}>
          <div className={classNames.headingDiv}>
            <div>Company</div>
            <div>Description</div>
            <div>Industries</div>
            <div>Founders</div>
            <div>Headquarters</div>
            <div>Status</div>
          </div>
          <div className={classNames.dataTotalContainer}>
            {mapDataItems?.map((eachItem) => {
              return <DataContainer {...eachItem} />;
            })}
          </div>
          <div className={classNames.footerDiv}>
            <div>
              <div>Prev</div>
              <div>1-50</div>
              <div>Next</div>
            </div>
            <div className={classNames.checkBox}>
              <div>
                <div>
                  <input type="checkbox" id="sharetokencheckbox" />
                  <label for="sharetokencheckbox">ShareTokens</label>
                </div>
                <div>
                  <input type="checkbox" id="safetokencheckbox" />
                  <label for="safetokencheckbox">SafeTokens</label>
                </div>
                <div>
                  <input type="checkbox" id="csoptcheckbox" />
                  <label for="csoptcheckbox">CSOPT</label>
                </div>
                <div>
                  <input type="checkbox" id="esoptcheckbox" />
                  <label for="esoptcheckbox">ESOPT</label>
                </div>
                <div>
                  <input type="checkbox" id="bondscheckbox" />
                  <label for="bondscheckbox">Bonds</label>
                </div>
              </div>
              <div className={classNames.clearBtn} onClick={uncheckAll}>
                Clear
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Directory;

const SingleFilterItem = ({ title }) => {
  const [divOpened, setDivOpened] = useState(false);
  return (
    <div
      className={classNames.singleItem}
      onClick={() => setDivOpened(!divOpened)}
    >
      <div>{title}</div>
      <div>
        <AiOutlineDown style={{ rotate: divOpened ? '180deg' : '' }} />
      </div>
    </div>
  );
};

const DataContainer = ({
  company,
  icon,
  description,
  industries,
  founders,
  location,
  status,
}) => {
  return (
    <div className={classNames.dataContainer}>
      <div>
        <img src={icon} alt={company} />
        <div>{company}</div>
      </div>
      <div>{description}</div>
      <div>
        {industries.map((eachItem) => {
          return <div>{eachItem}</div>;
        })}
      </div>
      <div>
        {founders.map((eachItem) => {
          return <div>{eachItem}</div>;
        })}
      </div>
      <div>
        {location.map((eachItem) => {
          return <div>{eachItem}</div>;
        })}
      </div>
      <div>{status}</div>
    </div>
  );
};
