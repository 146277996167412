import axios from 'axios';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { videosInPlaylist } from '../APIs/educationapi';
import { BankContext } from './Context';

export const PortfolioContext = createContext();
function PortfolioContextProvider({ children }) {
  // const { icedContracts } = useContext(BankContext);
  const [coinContract, setCoinContract] = useState('');
  const [filterCurrency, setFilterCurrency] = useState('Cryptocurrency');
  const [icingStep, setIcingStep] = useState(0);
  const [icingDays, setIcingDays] = useState(0);
  const [contractCount, setContractCount] = useState(1);
  const [createContractLoading, setCreateContractLoading] = useState(false);
  const [showGrowAssets, setShowGrowAssets] = useState(true);
  const [iceGrowTitle, setIceGrowTitle] = useState('');
  const [pageOnClose, setPageOnClose] = useState('/markets');
  const [coinCheckOut, setCoinCheckOut] = useState({});
  const [iceEnable, setIceEnable] = useState(false);
  const [youtubeApiData, setYoutubeApiData] = useState('');
  const [youtubeApiVideoData, setYoutubeApiVideoData] = useState('');
  const [youtubeApiPlaylistData, setYoutubeApiPlaylistData] = useState('');
  const [educationLeftSelected, seteducationLeftSelected] = useState('Youtube');
  const [selectedPlaylistData, setSelectedPlaylistData] = useState('');
  const [allPlaylistAvailable, setallPlaylistAvailable] = useState('');
  const [educationSelectedPlaylist, seteducationSelectedPlaylist] =
    useState('');
  var playListidDefault = '';

  useEffect(() => {
    axios
      .get(
        'https://www.googleapis.com/youtube/v3/search?part=snippet&key=AIzaSyBI1dwY4RVDDoTi28ojCOHD9bPHapHMIeU&channelId=UChUozFUDbu90WmrA_0-4S1Q'
      )
      .then((response) => {
        let filteredVideos = response.data.items.filter((eachData) => {
          return eachData.id.kind.includes('video');
        });
        setYoutubeApiData(response.data);
        setYoutubeApiVideoData(filteredVideos);
      })
      .catch((error) => {
        console.log(error, 'youtube error');
      });
    axios
      .get(
        'https://youtube.googleapis.com/youtube/v3/playlists?part=snippet%2CcontentDetails&channelId=UChUozFUDbu90WmrA_0-4S1Q&key=AIzaSyBI1dwY4RVDDoTi28ojCOHD9bPHapHMIeU'
      )
      .then(async (response) => {
        if (response?.data?.items[0]?.id) {
          playListidDefault = response?.data?.items[0]?.id;
          let playlistData = await videosInPlaylist(
            response?.data?.items[0]?.id
          );
          setSelectedPlaylistData(playlistData);
        }
        setYoutubeApiPlaylistData(response?.data?.items[0]);
        setallPlaylistAvailable(response?.data?.items);
      })
      .catch((error) => {
        console.log('playlist error', error);
      });
  }, []);

  return (
    <PortfolioContext.Provider
      value={{
        coinContract,
        setCoinContract,
        // icedContracts,
        filterCurrency,
        setFilterCurrency,
        icingStep,
        setIcingStep,
        icingDays,
        setIcingDays,
        contractCount,
        setContractCount,
        createContractLoading,
        setCreateContractLoading,
        showGrowAssets,
        setShowGrowAssets,
        iceGrowTitle,
        setIceGrowTitle,
        pageOnClose,
        setPageOnClose,
        coinCheckOut,
        setCoinCheckOut,
        iceEnable,
        setIceEnable,
        youtubeApiData,
        setYoutubeApiData,
        youtubeApiVideoData,
        setYoutubeApiVideoData,
        youtubeApiPlaylistData,
        setYoutubeApiPlaylistData,
        educationLeftSelected,
        seteducationLeftSelected,
        selectedPlaylistData,
        setSelectedPlaylistData,
        allPlaylistAvailable,
        setallPlaylistAvailable,
        educationSelectedPlaylist,
        seteducationSelectedPlaylist,
      }}
    >
      {children}
    </PortfolioContext.Provider>
  );
}

export default PortfolioContextProvider;
