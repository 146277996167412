import React, { useContext, useEffect } from 'react';
import classNames from './bplanner.module.scss';
// import retiredIcon from '../../static/images/sidebar-icons/retired.svg';
import streamIcon from '../../static/images/sidebar-icons/stream.svg';
import useWindowDimensions from '../../utils/WindowSize';
import axios from 'axios';
import { useState } from 'react';
import RetireContent from '../../components/Retire/RetireContent';
import RetireVideos from '../../components/Retire/RetireVideos';
import { PortfolioContext } from '../../context/PortfolioContext';
import EducationContainer from '../../components/React_Education';
import { GlobalContext } from '../../components/context/globalcontext';

function EducationPage() {
  const { width, height } = useWindowDimensions();
  const {
    youtubeApiData,
    setYoutubeApiData,
    youtubeApiVideoData,
    setYoutubeApiVideoData,
  } = useContext(PortfolioContext);

  const { setnewNavbarMenu } = useContext(GlobalContext);
  function handleMouseLeaveProduct() {
    setnewNavbarMenu('');
  }

  return (
    <div
      onMouseEnter={handleMouseLeaveProduct}
      style={{ height: '100%', width: '100%' }}
    >
      <EducationContainer />
    </div>
  );
}

export default EducationPage;
