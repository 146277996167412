import React, { useState } from 'react';
import classNames from './productscomponent.module.scss';

import portfolioIcon from './assets/portfolio.svg';
import vaultIcon from './assets/vault.svg';
import virtualIcon from './assets/virtual.svg';

const mapData = [
  {
    title: 'Portfolio ',
    box: [
      {
        name: 'Portfolio.Ai',
        para: ' Buy and sell tokenized shares of private companies in minutes',
        image: portfolioIcon,
      },
    ],
  },
  {
    title: 'Custody',
    box: [
      {
        name: 'Vaults',
        para: ' Buy and sell tokenized shares of private companies in minutes',
        image: vaultIcon,
      },
    ],
  },
  {
    title: 'Discovery',
    box: [
      {
        name: 'VirtualProspectus',
        para: ' Buy and sell tokenized shares of private companies in minutes',
        image: virtualIcon,
      },
      {
        name: 'VCFunnel',
        para: ' Buy and sell tokenized shares of private companies in minutes',
        image: portfolioIcon,
      },
    ],
  },
  {
    title: 'Markets',
    box: [
      {
        name: 'Primary Market',
        para: ' Buy and sell tokenized shares of private companies in minutes',
        image: portfolioIcon,
      },
      {
        name: 'Secondary Market',
        para: ' Buy and sell tokenized shares of private companies in minutes',
        image: portfolioIcon,
      },
    ],
  },
];

const LeftComponent = () => {
  return (
    <div className={classNames.leftComponent}>
      <ul>
        <li>Investors</li>
        <li>Companies</li>
        <li>Developers</li>
        <li>Landlords</li>
        <li>Brokers & Intermediaries</li>
        <li>Platform Operators</li>
      </ul>
    </div>
  );
};

export default LeftComponent;

export const RightComponent = () => {
  return (
    <div className={classNames.rightComponent}>
      {mapData?.map((eachData) => {
        return <DetailedRight {...eachData} />;
      })}
    </div>
  );
};

export const DetailedRight = ({ title, box }) => {
  const [divOpened, setDivOpened] = useState(false);
  return (
    <div
      className={classNames.detailedDiv}
      style={{ background: divOpened ? 'white' : '' }}
    >
      <div
        className={classNames.topDiv}
        onClick={() => setDivOpened(!divOpened)}
      >
        <div>{title} </div>
        <div>{divOpened ? '-' : '+'}</div>
      </div>
      <div
        className={classNames.bottomDivParent}
        style={{ display: divOpened ? '' : 'none' }}
      >
        {box.map((eachData) => {
          return <BottomDiv {...eachData} />;
        })}
      </div>
    </div>
  );
};

const BottomDiv = ({ name, para, image }) => {
  return (
    <div className={classNames.bottomDiv}>
      <div className={classNames.title}>{name}</div>
      <div className={classNames.para}>{para}</div>
      <div className={classNames.btnDiv}>
        <div>
          {' '}
          <svg
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.184 8.03449L9.46716 0.518272C9.36138 0.378835 9.21303 0.299999 9.05766 0.299999H6.60107C6.11256 0.299999 5.85887 1.01167 6.19156 1.44875L9.25166 5.46938H1.17787C0.693314 5.46938 0.300049 5.9499 0.300049 6.54197V10.4586C0.300049 11.0506 0.693314 11.5312 1.17787 11.5312H9.25122L6.19112 15.5512C5.85843 15.9883 6.11212 16.7 6.60063 16.7H9.05722C9.21259 16.7 9.36094 16.6212 9.46672 16.4817L15.1835 8.96497C15.3833 8.70272 15.3833 8.29674 15.184 8.03449Z"
              fill="#020202"
            />
          </svg>
        </div>
        <div>
          <img src={image ? image : ''} alt="image" />
        </div>
      </div>
    </div>
  );
};
