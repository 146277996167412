import privateEquityIcon from '../../../assets/images/shareToken.svg';
import realEstateIcon from '../../../assets/images/realEstate.svg';
import corporateBondIcon from '../../../assets/images/bonds.svg';

export const mapDiffData = [
  {
    title: 'Private Equities',
    image: privateEquityIcon,
    para: 'Buy and sell shares in private equities, real estate and corporate debt all in one place. ',
    mapItems: ['Startups', 'Established Companies', 'Investment Bankers'],
  },
  {
    title: 'Real Estate',
    image: realEstateIcon,
    para: 'Buy and sell shares in private equities, real estate and corporate debt all in one place. ',
    mapItems: ['Developers', 'Sellers', 'Realtors', 'Landlords'],
  },
  {
    title: 'Corporate Bonds',
    image: corporateBondIcon,
    para: 'Buy and sell shares in private equities, real estate and corporate debt all in one place. ',
    mapItems: ['Startups', 'Established Companies', 'Investment Bankers'],
  },
];
