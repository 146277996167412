import React, { useContext, useEffect, useRef, useState } from 'react';
import { BrowserRouter, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-loading-skeleton/dist/skeleton.css';
import Routess from './Routes';
import './app.css';
import { GlobalContext } from './components/context/globalcontext';
import TopCard from './components/topcard/topcard';
// import Navbar from './components/Navbar';
import axios from 'axios';
import Navbar from './components/Landingpage/Navbar/Navbar';
import HomeNavbar from './components/Landingpage/HomeNavbar/Navbar';
import vaultIcon from './assets/imported/images/logos/tokenHashIcon.svg';
import RootNav from './components/RootNavbar';
import ProductDropdown, {
  CompanyDropdown,
  DirectoryDropdown,
  MarketverseDropdown,
  CompanyDropdownRoot,
  
} from './components/Dropdown';
import AppContextProvider from './context/AppContext';
import PortfolioContextProvider from './context/PortfolioContext';
import { BankContext } from './context/Context';
import ImNavbar from './components/ImNavbar';
import RootFile from './pages/RootFile';
import NavbarNew from './components/Landingpage/Navbar/NavbarNew';
function App() {
  const similarSection = useRef();
  const cryptoref = useRef();
  const forexref = useRef();
  const moneymarketsref = useRef();
  const sharetokensref = useRef();
  const bondsref = useRef();
  const nftref = useRef();
  const fundcoinsref = useRef();
  const [divheight, setdivheight] = useState(0);
  // const indexfundsref = useRef();
  const [isVisible, setIsVisible] = useState(false);
  const [navOpen, setNavOpen] = useState(false);

  // todo sidebar
  const [opencard, setopencard] = useState('translateX(-140%)');
  const [therightcard, settherightcard] = useState('translateX(-140%)');
  const [closecard, setclosecard] = useState('');
  const [pagemask, setpagemask] = useState('');
  const [closedisp, setclosedisp] = useState('none');
  const [closerightdisp, setcloserightdisp] = useState('none');
  const [icondisp, seticondisp] = useState('none');

  // ? circle data
  const [selectedbondCurrencies, setSelectedBondCurrencies] =
    useState('index0');
  const [cryptoCircleLoading, setCryptoCircleLoading] = useState(true);
  const [cryptoCircle, setCryptoCircle] = useState();
  const [forexCircle, setForexCircle] = useState();
  const [moneyMarkets4Containers, setmoneyMarkets4Containers] = useState();
  const [bondCurrencies, setbondCurrencies] = useState([]);
  const [bondQueries, setbondQueries] = useState();
  const [bondQueriesLoading, setbondQueriesLoading] = useState();
  const [shareTokensSide, setShareTokensSide] = useState();
  const [fundSide, setFundSide] = useState();

  const [productDropdown, setProductDropdown] = useState(false);

  const [currentPath, setCurrentPath] = useState('/');
  const [selectedBondsNav, setSelectedBondsNav] = useState('Stats');

  //imported things for bond
  const [userType, setUserType] = useState('');
  const [adminEmail, setAdminEmail] = useState('');
  const [email, setEmail] = useState(
    localStorage.getItem('nvestBankLoginAccount') || ''
  );
  const [accessToken, setAccessToken] = useState(
    localStorage.getItem('nvestBankAccessToken') || ''
  );
  const [idToken, setIdToken] = useState(
    localStorage.getItem('nvestBankIdToken') || ''
  );
  const [username, setUsername] = useState('');
  const [name, setName] = useState('');

  //imported
  const login = (paramEmail, paramAccessToken, paramIdToken) => {
    setEmail(paramEmail);
    setAdminEmail(paramEmail);
    setAccessToken(paramAccessToken);
    setIdToken(paramIdToken);
  };

  //imported toast context
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState('');
  const [subMessage, setSubMessage] = useState('');
  const [icon, setIcon] = useState(vaultIcon);

  //dropdown menu
  const [selectedOpt, setSelectedOpt] = useState('investors');
  const [selectedSubNav, setselectedSubNav] = useState('sharetokens');

  const [isScrolled, setIsScrolled] = useState(false);
  const [navItemClicked, setnavItemClicked] = useState('home');
  const [newNavbarMenu, setnewNavbarMenu] = useState(false);

  const [imnavbar, setimnavbar] = useState('IM');

  function toastShowOn(message, submessage, icon) {
    setShow(true);
    setMessage(message);
    setSubMessage(submessage);
    setIcon(icon || vaultIcon);
    setTimeout(() => {
      setShow(false);
      setMessage('');
      setSubMessage('');
      setIcon('');
    }, 2000);
  }

  const value = {
    setdivheight,
    divheight,
    navOpen,
    setNavOpen,
    similarSection,
    isVisible,
    setIsVisible,
    // indexfundsref,
    cryptoref,
    forexref,
    moneymarketsref,
    sharetokensref,
    bondsref,
    nftref,
    fundcoinsref,
    opencard,
    closecard,
    pagemask,
    therightcard,
    closedisp,
    closerightdisp,
    icondisp,
    setopencard,
    setclosecard,
    setpagemask,
    settherightcard,
    setclosedisp,
    setcloserightdisp,
    seticondisp,
    cryptoCircle,
    setCryptoCircle,
    forexCircle,
    setForexCircle,
    moneyMarkets4Containers,
    setmoneyMarkets4Containers,
    bondCurrencies,
    setbondCurrencies,
    cryptoCircleLoading,
    setCryptoCircleLoading,
    bondQueries,
    setbondQueries,
    selectedbondCurrencies,
    setSelectedBondCurrencies,
    bondQueriesLoading,
    setbondQueriesLoading,
    shareTokensSide,
    setShareTokensSide,
    fundSide,
    setFundSide,
    productDropdown,
    setProductDropdown,
    currentPath,
    setCurrentPath,
    selectedBondsNav,
    setSelectedBondsNav,
    //imported for bonds
    userType,
    setUserType,
    adminEmail,
    setAdminEmail,
    email,
    setEmail,
    accessToken,
    setAccessToken,
    idToken,
    setIdToken,
    login,
    username,
    name: name || username,
    toastShowOn,

    //dropdown menu
    selectedOpt,
    setSelectedOpt,
    selectedSubNav,
    setselectedSubNav,
    isScrolled,
    setIsScrolled,
    navItemClicked,
    setnavItemClicked,
    newNavbarMenu,
    setnewNavbarMenu,
    imnavbar,
    setimnavbar,
  };

  async function getCircleData() {
    setCryptoCircleLoading(true);
    await axios
      .post('https://comms.globalxchange.com/coin/vault/service/coins/get', {
        app_code: 'indianinvestor',
        type: 'crypto',
      })
      .then((data) => {
        setCryptoCircle(data.data);
      });

    await axios
      .post('https://comms.globalxchange.com/coin/vault/service/coins/get', {
        app_code: 'indianinvestor',
        type: 'fiat',
      })
      .then((data) => {
        setForexCircle(data.data);
      });

    await axios
      .get(
        'https://comms.globalxchange.com/coin/iced/get/liquid/interest?app_code=canearn'
      )
      .then((data) => {
        setmoneyMarkets4Containers(data.data);
      });

    // ? currencies query

    await axios
      .get(
        'https://comms.globalxchange.com/coin/iced/banker/custom/bond/get?status=active&email=shorupan@indianinvestor.com'
      )
      .then((data) => {
        let ObjOfObjs = Object.entries(data.data.currenciesData).map(
          (e) => e[1]
        );

        setbondCurrencies(ObjOfObjs);

        // ? bond query

        if (!bondQueries) {
          bondQuery(ObjOfObjs[0].coin || 'BTC');
        }
      });
    setCryptoCircleLoading(false);
  }

  async function bondQuery(selectedcoin) {
    setbondQueriesLoading(false);
    await axios
      .get(
        `https://comms.globalxchange.com/coin/iced/banker/custom/bond/list/data/get?list_id=3ykzb16dtpl5greu5x&coin=${selectedcoin}`
      )
      .then((data) => {
        setbondQueries(data.data.bondsListData);
      });
    setbondQueriesLoading(true);
  }

  async function queryBondData() {
    setbondQueriesLoading(false);
    var selectedcoinquery = 0;

    bondCurrencies.forEach((data, index) => {
      if (selectedbondCurrencies === 'index' + index) {
        selectedcoinquery = data.coin;
      }
    });

    await axios
      .get(
        `https://comms.globalxchange.com/coin/iced/banker/custom/bond/list/data/get?list_id=5zv3h0pl2jnq2q8&coin=${selectedcoinquery}`
      )
      .then((data) => {
        setbondQueries(data.data.bondsListData);
        setbondQueriesLoading(true);
      });
  }

  async function shareTokensQuery() {
    await axios
      .get(
        `https://comms.globalxchange.com/coin/investment/path/get?country=India&investmentType=EQT`
      )
      .then((data) => {
        setShareTokensSide(data.data.paths);
      });
  }

  async function fundsQuery() {
    await axios
      .get(
        `https://comms.globalxchange.com/coin/investment/path/get?investmentType=funds`
      )
      .then((data) => {
        setFundSide(data.data.paths);
      });
  }

  useEffect(() => {
    queryBondData();
    shareTokensQuery();
    fundsQuery();
    getCircleData();
  }, [selectedbondCurrencies]);

  // useEffect(() => {
  //   if (navItemClicked == 'products') {
  //     setnavItemClicked('');
  //   }
  // }, [newNavbarMenu]);

  useEffect(() => {
    const homepagenew = document.getElementById('homepagenew');
    if (homepagenew) {
      homepagenew.addEventListener('scroll', handleScroll);
      return () => homepagenew.removeEventListener('scroll', handleScroll);
    }
  }, [navItemClicked]);

  const handleScroll = () => {
    const homepagenew = document.getElementById('homepagenew');
    // console.log('working');
    if (homepagenew.scrollTop > 5) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  return (
    <BrowserRouter>
      <GlobalContext.Provider value={value}>
        <AppContextProvider>
          <PortfolioContextProvider
          // key={refreshPage === 'portfolio'.toString()}
          >
            <ProductDropdown />
            {/* <DirectoryDropdown /> */}
            <MarketverseDropdown />
            <CompanyDropdown />
            <CompanyDropdownRoot />
            <TopCard />
            {/* <Navbar /> */}
            {(navItemClicked == 'im' && window.location.pathname == '/im') ||
            window.location.pathname == '/im/directory' ? (
              <ImNavbar />
            ) : navItemClicked == 'home' && window.location.pathname === '/' ? (
              // <HomeNavbar />
              // <RootNav/>
              <NavbarNew />
            ) : window.location.pathname !== '/vp/template' ? (
              <Navbar />
            ) : (
              ''
            )}
            <Routess />
            <div
              className={productDropdown ? 'dropdownactive' : ''}
              style={{ bottom: productDropdown ? '0' : '' }}
              onClick={() => {
                setProductDropdown(!productDropdown);
              }}
            ></div>
          </PortfolioContextProvider>
        </AppContextProvider>
      </GlobalContext.Provider>
    </BrowserRouter>
  );
}

export default App;
