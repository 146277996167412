import axios from 'axios';

const API_KEY = process.env.REACT_APP_YOUTUBE_S_KEY;
let Youtube_API = 'AIzaSyBI1dwY4RVDDoTi28ojCOHD9bPHapHMIeU';

export const videosInPlaylist = async (playListId) => {
  try {
    const data = await axios.get(
      `https://youtube.googleapis.com/youtube/v3/playlistItems?part=snippet%2CcontentDetails&playlistId=${playListId}&key=${Youtube_API}`
    );
    return data.data;
  } catch (error) {
    console.error(error, 'videosInPlaylist');
  }
};

//PLvM7O5s4wrN1tM5Y5FO3oTQCIJEMCmvKa -> playlistid
