export const openAppIndianInvestor = () => {
  window.open('https://app.indianinvestor.com/', '_blank');
};

export const openAppIndianInvestorSame = () => {
  window.location.assign('https://app.indianinvestor.com');
};

export function createGroups(arr) {
  const numGroups = Math.ceil(arr.length / 4);
  return new Array(numGroups)
    .fill('')
    .map((_, i) => arr.slice(i * 4, (i + 1) * 4));
}

export function createGroups2(arr) {
  const numGroups = Math.ceil(arr.length / 2);
  return new Array(numGroups)
    .fill('')
    .map((_, i) => arr.slice(i * 2, (i + 1) * 2));
}
