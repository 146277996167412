import React, { useContext, useLayoutEffect, useRef, useState } from 'react';
import { GlobalContext } from '../../context/globalcontext';
import MainNavbar from './MainNavbar';
import TopNavbar from './TopNavbar';
import { HashLink } from 'react-router-hash-link';
import crypto from '../../../assets/productdropdown1/crypto.png';
import forex from '../../../assets/productdropdown1/forex.png';
import moneymarkets from '../../../assets/productdropdown1/moneymarkets.png';
import bonds from '../../../assets/productdropdown1/bonds.png';
import sharetoken from '../../../assets/productdropdown1/sharetoken.png';
import fundcoin from '../../../assets/productdropdown1/fundcoin.png';
import nft from '../../../assets/productdropdown1/nft.png';
import { AnimatePresence } from 'framer-motion/dist/framer-motion';
import SidebarMobile from '../../SideBar';
import { Link } from 'react-router-dom';
import portfolio from '../../../static/images/portfolio.svg';
import arrow from '../../../static/images/clickArrow.svg';
import { investormenu, companiesmenu } from './menubar';

const HomeNavbar = () => {
  const dropDownData = [
    crypto,
    forex,
    moneymarkets,
    bonds,
    sharetoken,
    fundcoin,
    nft,
  ];
  const {
    isVisible,
    productDropdown,
    setProductDropdown,
    setNavOpen,
    navOpen,
    setPlatform,
    setInviteModal,
    // wholeNavbar,
    // currentPath,
    // setCurrentPath,
    selectedOpt,
    setSelectedOpt,
    selectedSubNav,
    navItemClicked,
    isScrolled,
  } = useContext(GlobalContext);

  const handleModalClose = () => {
    setPlatform('');
    setInviteModal(false);
  };

  function toggleNavbar(event) {
    const scrollNavbarItems = document.querySelectorAll('.scrollNavbarItems');
    scrollNavbarItems.forEach((item) => {
      item.classList.remove('selected');
    });

    event.target.classList.add('selected');
  }

  function setRightSideBorder(getValue) {
    const parent = document.querySelector('.productDropdownFirst').childNodes;
    var selected = '';
    parent.forEach((data) => {
      if (data.className == getValue) {
        selected = getValue;
      }
    });
    parent.forEach((data) => {
      if (data.classList[1] == 'selectedDropdown') {
        data.classList.remove('selectedDropdown');
      }
    });
    document.querySelector(`.${selected}`).classList.add('selectedDropdown');
  }

  function productsDropdownFunc(event) {
    setProductDropdown(!productDropdown);
    setRightSideBorder(event.target.className);
  }
  // console.log('window', navItemClicked && window.location.pathname);
  return (
    <div
      className="wholeNavbar"
      style={{
        height: productDropdown ? 'calc(67px + 35vh)' : '10vh', //removed 10vh in else place
        top:
          navItemClicked && window.location.pathname != '/'
            ? ''
            : isScrolled
            ? ''
            : '0',
      }}
    >
      <TopNavbar productDropdown={productDropdown} />
      <MainNavbar productDropdown={productDropdown} />
      {/* <div
        className="scrollNavbar"
        style={{ display: isVisible ? 'flex' : 'none' }}
      >
        <HashLink
          className={`scrollNavbarItems ${
            selectedSubNav == 'sharetokens' ? 'selected' : ''
          }`}
          onClick={toggleNavbar}
          to="/#sharetokens"
        >
          ShareTokens
        </HashLink>

        <HashLink
          className={`scrollNavbarItems ${
            selectedSubNav == 'crypto' ? 'selected' : ''
          }`}
          onClick={toggleNavbar}
          to="/#crypto"
        >
          Crypto
        </HashLink>

        <HashLink
          className={`scrollNavbarItems ${
            selectedSubNav == 'forex' ? 'selected' : ''
          }`}
          onClick={toggleNavbar}
          to="/#forex"
        >
          Forex
        </HashLink>

        <HashLink
          className={`scrollNavbarItems ${
            selectedSubNav == 'fundcoins' ? 'selected' : ''
          }`}
          onClick={toggleNavbar}
          to="/#fundcoins"
        >
          FundCoins
        </HashLink>

        <HashLink
          className={`scrollNavbarItems ${
            selectedSubNav == 'nft' ? 'selected' : ''
          }`}
          onClick={toggleNavbar}
          to="/#nft"
        >
          NFTs
        </HashLink>
      </div> */}
      <div
        className="productDropdown"
        style={{
          transform: productDropdown ? 'translateY(0px)' : 'translateY(-70vh)',
          height: isVisible ? 'calc(35vh + 36px)' : '35vh',
        }}
      >
        <div
          className="productDropdownFirst"
          style={{ paddingTop: isVisible ? '56px' : '' }}
        >
          <div
            onClick={(event) => {
              setRightSideBorder(event.target.className);
              setSelectedOpt('investors');
            }}
            className="crypto selectedDropdown"
          >
            For Investors
          </div>
          <div
            className="forex"
            onClick={(event) => {
              setRightSideBorder(event.target.className);
              setSelectedOpt('companies');
            }}
          >
            For Companies
          </div>
          <div
            className="moneymarkets"
            style={{ opacity: 0.25, cursor: 'not-allowed' }}
          >
            For VC's & Angels
          </div>
          <div
            className="bonds"
            style={{ opacity: 0.25, cursor: 'not-allowed' }}
          >
            For NRI's
          </div>
        </div>
        <div className="selectedOpt-div">
          <>
            {selectedOpt === 'investors'
              ? investormenu.map((e, i) => {
                  return (
                    <div className="selectedOpt" key={e.name}>
                      <div style={{ display: 'flex' }} className="margintop">
                        {e.name}
                      </div>
                      <div className="hover-show">
                        <div
                          style={{
                            fontWeight: '500',
                            color: '#5F6163',
                            fontSize: '1rem',
                            lineHeight: '1.6',
                          }}
                        >
                          {e.description}
                        </div>
                        <div className="zoom-div">
                          <div
                            className="zoom2"
                            onClick={() => {
                              if (e.link) {
                                window.open(e.link);
                              }
                            }}
                          >
                            <div
                              className="zoom1"
                              style={{ cursor: 'pointer' }}
                            >
                              <span
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-around',
                                  alignItems: 'center',
                                  height: '40px',
                                }}
                              >
                                <div className="check1-text">CHECK</div>
                                <img
                                  className="clickarrow-img"
                                  src={arrow}
                                  alt=""
                                />
                              </span>
                            </div>
                          </div>
                          <div className="icon-div">
                            <img src={e.icon} alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              : companiesmenu.map((e, i) => {
                  return (
                    <div className="selectedOpt" key={e.name}>
                      <div style={{ display: 'flex' }} className="margintop">
                        {e.name}
                      </div>
                      <div className="hover-show">
                        <div
                          style={{
                            fontWeight: '500',
                            color: '#5F6163',
                            fontSize: '1rem',
                            lineHeight: '1.6',
                          }}
                        >
                          {e.description}
                        </div>
                        <div className="zoom-div">
                          <div
                            className="zoom2"
                            onClick={() => window.open(e.link)}
                          >
                            <div
                              className="zoom1"
                              style={{ cursor: 'pointer' }}
                            >
                              <span
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-around',
                                  alignItems: 'center',
                                  height: '40px',
                                }}
                              >
                                <div className="check1-text">CHECK</div>
                                <img
                                  className="clickarrow-img"
                                  src={arrow}
                                  alt=""
                                />
                              </span>
                            </div>
                          </div>
                          <div>
                            <img src={e.icon} alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
          </>
        </div>
      </div>
      <AnimatePresence exitBeforeEnter initial={false}>
        {navOpen && (
          <SidebarMobile
            onClose={() => setNavOpen(false)}
            setPlatform={setPlatform}
            setInviteModal={setInviteModal}
          />
        )}
      </AnimatePresence>
    </div>
  );
};

export default HomeNavbar;
