import React, { createContext, useContext, useMemo, useState } from 'react';
// import { useLoadAppDetails } from '../queryHooks';
import { APP_CODE } from '../config/appConfig';

export const AppContext = createContext();

const AppContextProvider = ({ children }) => {
  // const { appByCode, appByCodeLoading } = useLoadAppDetails(APP_CODE);

  //Mobile View Data

  const [openSidebar, setOpenSidebar] = useState(false);
  const [selectedCurrencyType, setSelectedCurrencyType] = useState('crypto');
  const [selectedMenu, setSelectedMenu] = useState('market');
  const [selectedSubMenu, setSelectedSubMenu] = useState('Crypto');

  // vault sections
  const [selectedView, setSelectedView] = useState('Overview');
  const [selectedCurrency, setSelectedCurrency] = useState('');

  //BuySell Flow
  const [selectedCoin, setSelectedCoin] = useState(null);
  const [selectedOperation, setSelectionOperation] = useState(null);
  const [buySellStep, setBuySellStep] = useState(null);
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [tradeResponse, setTradeResponse] = useState(null);

  // Action Flow
  const [actionFlow, setActionFlow] = useState(null);

  // BuyBond
  const [selectedBond, setSelectedBond] = useState(null);

  // const appData = useMemo(
  //   () => ({
  //     appName: appByCode?.app_name,
  //     appCode: APP_CODE,
  //     appLogo: appByCode?.app_icon,
  //     appFullLogo: appByCode?.data?.color_logo,
  //     appColorCode: `#${appByCode?.color_codes?.[0]?.primarycolourcode}`,
  //     appCurrencyName: appByCode?.data?.currencyname,
  //     appCurrencySymbol: appByCode?.data?.currencyname,
  //     // websiteTitle: appByCode?.data?.website_title,
  //     websiteTitle: 'Retire Sooner With The Retired App | #PlanB',
  //     // websiteDescription: appByCode?.data?.website_description,
  //     websiteDescription:
  //       'Retired.App is the worlds first cryptocurrency based retirement platform. Take control of your financial future today by joining Retired.App.',
  //     ownerEmail: appByCode?.operatorData?.email,
  //     registrationLink: appByCode?.registration_link,
  //     token: appByCode?.shareTokenData?.token,
  //   }),
  //   [appByCode]
  // );

  // const {
  //   appName,
  //   appCode,
  //   appLogo,
  //   appFullLogo,
  //   appColorCode,
  //   appCurrencyName,
  //   appCurrencySymbol,
  //   websiteTitle,
  //   websiteDescription,
  //   ownerEmail,
  //   registrationLink,
  //   token,
  // } = appData;

  return (
    <AppContext.Provider
      value={{
        // appName,
        // appCode,
        // appLogo,
        // appFullLogo,
        // appColorCode,
        // appCurrencyName,
        // appCurrencySymbol,
        // websiteTitle,
        // websiteDescription,
        // ownerEmail,
        // // appByCode,
        // appDetailsLoading: appByCodeLoading,
        // ownerEmail,
        // registrationLink,
        // token,

        //Mobile View data

        openSidebar,
        setOpenSidebar,
        selectedCurrencyType,
        setSelectedCurrencyType,
        selectedMenu,
        setSelectedMenu,
        selectedSubMenu,
        setSelectedSubMenu,
        selectedView,
        setSelectedView,
        selectedCurrency,
        setSelectedCurrency,

        //Buy Sell Flow Mobile
        selectedCoin,
        setSelectedCoin,
        selectedOperation,
        setSelectionOperation,
        buySellStep,
        setBuySellStep,
        selectedAsset,
        setSelectedAsset,
        tradeResponse,
        setTradeResponse,

        // Action Flow
        actionFlow,
        setActionFlow,

        //Buy Bond
        selectedBond,
        setSelectedBond,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;

export const useAppContextDetails = () => useContext(AppContext);
