import React, { useContext, useEffect, useState } from 'react';
import classNames from './landingcontainer.module.scss';
import bgImage from './bgimage2.jpg';
import bgVideo from './bgvideo.mp4';
import { GlobalContext } from '../context/globalcontext';
import mobileIndianInvestor from '../../assets/images/mobileIndianInvestor.jpg';
import {
  openAppIndianInvestor,
  openAppIndianInvestorSame,
} from '../../assets/Functions/miniFunctions';

import taxchainsIcon from '../../assets/logos/halfcircle/taxchains.svg';

import swiggyIcon from '../../assets/logos/swiggy.svg';
import flipkartIcon from '../../assets/logos/flipkart.svg';
import olaIcon from '../../assets/logos/ola.svg';
import credIcon from '../../assets/logos/cred.svg';
import phonepeIcon from '../../assets/logos/phonepe.svg';

const leftScrollData = [
  {
    icon: swiggyIcon,
    title: 'Swiggy',
    para: 'Swiggy is a food delivery platform that brings food from neighbourhood restaurants directly to',
    returns: '$700M',
    cap: '$10.7B',
  },
  {
    icon: flipkartIcon,
    title: 'Flipkart',
    para: 'Flipkart operates an online shopping website with a registered customer base of over 100 mil....',
    returns: '$3.6B',
    cap: '$37.6B',
  },
];

const rightScrollData = [
  {
    icon: olaIcon,
    title: 'Ola',
    para: 'Ola is a mobility platform and ride-hailing company that integrates city transportation for...',
    returns: '$200M',
    cap: '$5B',
  },
  {
    icon: credIcon,
    title: 'Cred',
    para: 'CRED is a fintech startup that offers rewards for customers who use its platform to pay their',
    returns: '$140M',
    cap: '$6.4B',
  },
  {
    icon: phonepeIcon,
    title: 'PhonePe',
    para: 'PhonePe is a mobile payment application that enables users to transfer money instantly',
    returns: '$100M',
    cap: '$12B',
  },
];

const LandingContainer = () => {
  const [notCame, setnotCame] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setnotCame(true);
    }, 250);
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      let title1 = document.getElementById('changingTitle1');
      let title2 = document.getElementById('changingTitle2');
      let title3 = document.getElementById('changingTitle3');

      let style1;
      let style2;
      let style3;

      if (title1 && title2 && title3) {
        style1 = window?.getComputedStyle(title1);
        style2 = window?.getComputedStyle(title2);
        style3 = window?.getComputedStyle(title3);

        if (style1 && style1.display == 'block') {
          title1.style.display = 'none';
          title2.style.display = 'block';
          title3.style.display = 'none';
        } else if (style2.display == 'block') {
          title2.style.display = 'none';
          title3.style.display = 'block';
          title1.style.display = 'none';
        } else {
          title3.style.display = 'none';
          title1.style.display = 'block';
          title2.style.display = 'none';
        }
      }
    }, 3600);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className={classNames.landingContainer}>
      <div className={classNames.leftContainer}>
        <div className={classNames.title}>
          <div>
            <div id="changingTitle1">Capital Markets</div>
            <div id="changingTitle2">Investment Banking</div>
            <div id="changingTitle3">Liquid Markets</div>
          </div>
          <div>For The Indian Century</div>
        </div>
        <div className={classNames.para}>
          Buy and sell shares in private equities, real estate and corporate
          debt all in one place.
        </div>
        <div className={classNames.btnDiv}>
          <div
            // onClick={openAppIndianInvestorSame}
            onClick={() => {
              window.open('https://my.indianinvestor.com/');
            }}
          >
            Login   
          </div>
          <div
            // onClick={openAppIndianInvestor}
            onClick={() => {
              window.open('https://my.indianinvestor.com/register');
            }}
          >
            Get Started
          </div>
        </div>
      </div>
      <div className={classNames.rightContainer}>
        {/* <img src={mobileIndianInvestor} alt="mobileIndianInvestor" /> */}
        <FullCardsContainer />
        <HalfCardsContainer />
      </div>
    </div>
  );
};

export default LandingContainer;

const FullCardsContainer = () => {
  return (
    <div className={classNames.fullCardsContainer}>
      <div>
        {leftScrollData.map((eachData) => {
          return <SingleCards {...eachData} />;
        })}
      </div>
      <div>
        {leftScrollData.map((eachData) => {
          return <SingleCards {...eachData} />;
        })}
      </div>
      <div>
        {leftScrollData.map((eachData) => {
          return <SingleCards {...eachData} />;
        })}
      </div>
    </div>
  );
};

const HalfCardsContainer = () => {
  return (
    <div className={classNames.halfCardsContainer}>
      <div>
        {rightScrollData.map((eachData) => {
          return <SingleCards {...eachData} />;
        })}
      </div>
      <div>
        {rightScrollData.map((eachData) => {
          return <SingleCards {...eachData} />;
        })}
      </div>
      <div>
        {rightScrollData.map((eachData) => {
          return <SingleCards {...eachData} />;
        })}
      </div>
    </div>
  );
};

const SingleCards = ({ icon, title, para, returns, cap }) => {
  return (
    <div className={classNames.singleCards}>
      <div className={classNames.imageDiv}>
        <img src={icon} alt="icon" />
      </div>
      <div className={classNames.title}>{title}</div>
      <div className={classNames.para}>{para}</div>
      <div className={classNames.details}>
        <div>
          <div className={classNames.key}>Raising</div>
          <div className={classNames.value}>{returns}</div>
        </div>
        <div>
          <div className={classNames.key}>Market Cap</div>
          <div className={classNames.value}>{cap}</div>
        </div>
      </div>
      <div className={classNames.cardsBtn}>
        <div>SafeToken</div>
        <div>CSOPT</div>
      </div>
    </div>
  );
};
